<template>
    <div class="shop-container">
        <div class="titles d-flex bolder align-items-center flex-wrap w100 ">
            <span class="regular gray text-center" @click="limit='market'" :class="{'active':limit==='market'}" > بازار </span>
            <span class="regular gray text-center" @click="limit='limit'" :class="{'active':limit==='limit'}" > حد </span>
                    <span class="regular gray text-center" @click="limit='stop-limit'" :class="{'active':limit==='stop-limit'}" >  حدتوقف </span>
        </div>
        <div class="shop">
            <Buy  class="cont" :limit="limit" :balance="tomanBalance" :role="role"/>
            <Sell class="cont" :limit="limit" :balance="coinBalance" :role="role"/>
        </div>
    </div>
</template>

<script>
import Buy from './Buy.vue'
import Sell from './Sell.vue'
import {tradeFrom,tradeTo} from "@/Lib/reuseableFunction";

export default {
        name:'Shop',
        components:{
            Buy,
            Sell
        },
  computed:{
          tradeTo,tradeFrom
  },
        data() {
            return {
                limit:'market',
              tomanBalance:0,
              coinBalance:0,
              role: {}
            }
        },
methods:{
  async getWalletCredit() {
    this.state.loading = true
    if (this.state.userInfo.authorized) {
      const res = await this.$axios.get('/wallets/customer-addresses', {
        params: {
          walletType:'P2P',
          // coins: `${this.tradeFrom}_${this.tradeTo}`

        }
      })
      console.log(res)
      this.coinBalance = res[this.$route.params.tradeFrom].credit || 0
      this.tomanBalance = res[this.$route.params.tradeTo].credit || 0
      this.tomanBalance = res[this.$route.params.tradeTo].credit || 0

      //get role
      const coins = await this.$axios('/coins')
      this.role = coins.content.find((a) => a.coin === this.tradeFrom)
      console.log(this.role)
      this.state.loading = false


    }
  },
},
      mounted() {
        this.getWalletCredit()
      },


    }
</script>

<style lang="scss" scoped>
.active{
  color: var(--secondary);
  border-bottom : 2px solid var(--secondary);

}
.titles{
  gap: 1rem;
  font-weight: bolder;
  align-items: flex-end !important;
  cursor: pointer;
  span{
    height: 50px;
    width: 100%;
    max-width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.shop-container{
    display: flex;
    flex-direction: column;
height: 100%;
  background: #F8F8F8;
/* p2p cards */

box-shadow: 0px 0px 2px rgba(140, 150, 153, 0.34);
border-radius: 8px;
    width: 100%;
    .btns-container{
        display: flex;
        flex-direction: row;
        width: 100%;
        .filter-btn{
            width: 130px;
            color:#A1A1AC;
            text-align: center;
            cursor: pointer;
            padding: 4px 0;
            transition: 700ms;
        }
        .selected-btn{
            background: var(--white);
            box-shadow: 0px 0px 4px -1px rgba(65, 70, 192, 0.1);
            border-radius: 4px 4px 0px 0px;
            color: var(--secondary) !important;
            transform: translate(-1px,3px);
            z-index: 3;
        }
    }
    .shop{
        display: flex;
        flex-direction: row;
        column-gap: 12px;

        border-radius: 4px ;
        padding: 12px 20px;
        z-index: 3;
    }
}

@media (max-width:600px){
  .titles{
    flex-direction: column !important;
    align-items: center !important;
  }
.shop{
    flex-direction: column !important ;
  gap: 10px;
  .cont{
    width: 100%;
  }
}

}
</style>