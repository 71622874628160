<template>
  <form class="register-container">
    <h2>ورود</h2>
    <div class="btns">
      <button
        @click.prevent="isLoginEmail = true"
        :class="{ active: isLoginEmail }"
      >
        ایمیل
      </button>
      <button
        @click.prevent="isLoginEmail = false"
        :class="{ active: !isLoginEmail }"
      >
        موبایل
      </button>
    </div>

<transition enter-active-class="animate__animated animate__fadeIn">



  <div class="inputs-container" v-if="isLoginEmail">
    <div class="label-container">
      <div
          :class="emailWarn ? 'div-error' : femail ? 'div-active' : ''"
          class="input"
      >
        <label >
            <span
                :class="
                emailWarn
                  ? 'span-error'
                  : femail || data.email
                  ? 'span-active'
                  : ''
              "
            >
              آدرس ایمیل
            </span>
          <input @focus="femail = true" v-model="data.email" type="text" />
        </label>

        <inline-svg
            :class="emailWarn ? 'svg-error' : femail ? 'svg-active' : ''"
            class="svg"
            :src="require('../../assets/Icons/Email.svg')"
        />
      </div>
      <span v-if="emailWarn" class="warn"> {{ emailWarn }} </span>
    </div>

    <div class="label-container">
      <div
          :class="passWarn ? 'div-error' : fpass ? 'div-active' : ''"
          class="input"
      >
        <label >
            <span
                :class="
                passWarn
                  ? 'span-error'
                  : fpass || data.password
                  ? 'span-active'
                  : ''
              "
            >
              رمز عبور
            </span>
          <input @focus="fpass = true" v-model="data.password" :type="type" />
        </label>
        <inline-svg
            @click.prevent="type = type === 'password' ? 'text' : 'password'"
            :class="passWarn ? 'svg-error' : fpass ? 'svg-active' : ''"
            class="svg"
            :src="require('../../assets/Icons/password.svg')"
        />
      </div>
      <span v-if="passWarn" class="warn"> {{ passWarn }} </span>
    </div>
  </div>

  <div class="inputs-container" v-else>
    <div class="label-container">
      <div
          :class="emailWarn ? 'div-error' : femail ? 'div-active' : ''"
          class="input"
      >
        <label >
            <span
                :class="
                emailWarn
                  ? 'span-error'
                  : femail || data.email
                  ? 'span-active'
                  : ''
              "
            >
              شماره موبایل
            </span>
          <input @focus="femail = true" v-model="data.email" type="text" />
        </label>

        <inline-svg
            :class="emailWarn ? 'svg-error' : femail ? 'svg-active' : ''"
            class="svg"
            :src="require('../../assets/Icons/phone.svg')"
        />
      </div>
      <span v-if="emailWarn" class="warn"> {{ emailWarn }} </span>
    </div>

    <div class="label-container">
      <div
          :class="passWarn ? 'div-error' : fpass ? 'div-active' : ''"
          class="input"
      >
        <label >
            <span
                :class="
                passWarn
                  ? 'span-error'
                  : fpass || data.password
                  ? 'span-active'
                  : ''
              "
            >
              رمز عبور
            </span>
          <input @focus="fpass = true" v-model="data.password" :type="type" />
        </label>
        <inline-svg
            @click.prevent="type = type === 'password' ? 'text' : 'password'"
            :class="passWarn ? 'svg-error' : fpass ? 'svg-active' : ''"
            class="svg"
            :src="require('../../assets/Icons/password.svg')"
        />
      </div>
      <span v-if="passWarn" class="warn"> {{ passWarn }} </span>
    </div>
  </div>

</transition>





    <a href="" class="secondary regular" @click.prevent="$router.push('/auth/forgetpass')">فراموشی رمز عبور</a>

<!--    <button class="btn-primary" @click.prevent="loginUser">ورود</button>-->
    <button class="btn-primary" @click.prevent="loginUser">ورود</button>
    <span class="or">یا</span>
    <button class="btn-secondary-outline" @click.prevent="googleSso">
      <img src="../../assets/Icons/google.svg" alt="" />
      ورود از طریق گوگل
    </button>

    <span class="or regular">
      حساب کاربری ندارید؟
      <a href="" class="secondary" @click.prevent="$router.push('/auth/register')"
        >ثبت نام کنید
      </a>
    </span>
  </form>
</template>
<script>
export default {
  name: "login",
  data() {
    return {
      femail: false,
      fpass: false,
      type: "password",
      isLoginEmail: true,
      data: {
        email: "",
        password: "",
        referralCode: "",
        osType: "",
        deviceType: "",
        browser: "",
      },
    };
  },
  computed: {
    emailWarn() {
      let err;
      if (this.femail) {
        if (!this.data.email) {
          err = "ایمیل را وارد کنید";
        } else if (!this.$validEmail(this.data.email)) {
          err = "ایمیل صحیح را وارد کنید";
        } else {
          err = false;
        }
      } else {
        err = false;
      }
      return err;
    },

    passWarn() {
      let err;
      if (this.fpass) {
        if (!this.data.password) {
          err = "رمز عبور را وارد کنید";
        } else if (this.data.password.length < 6) {
          err = "رمز عبور باید حداقل 6 کاراکتر باشد";
        } else if (!this.$validPass(this.data.password)) {
          err = "رمز عبور صحیح را وارد کنید";
        } else {
          err = false;
        }
      } else {
        err = false;
      }
      return err;
    },
  },

  methods: {
    showingPass() {
      if (this.type === "password") {
        this.type = "text";
      } else if (this.type === "text") {
        this.type = "password";
      }
    },
    async checkEmail() {
      if (!this.$validEmail(this.data.email) || !this.data.email) {
        this.emailError = "ایمیل صحیح وارد نمایید";
        this.$error("اطلاعات وارد شده اشتباه است", "ایمیل صحیح وارد نمایید");
        return Promise.reject("repeat failed : " + this.data.email);
      } else {
        this.emailError = "";
      }
    },
    googleSso() {
      window.location.href = this.$google_sso;
    },
    //login with google
    async loginUser() {
      this.checkEmail();
      this.state.loading = true;
      const res = await this.$axios.post("/users/login", this.data);

      if (res.message == "login successful, otp is required") {
        //this function will set username as email into cookies
        this.$cookies.set("username", this.data.email);
        this.state.userInfo.email = this.data.email;
        this.state.userInfo.mobilenumber =
          res.baseDTO.starTwoStepAuthentication || "";
        this.state.towStepAuthenticationType =
          res.baseDTO.towStepAuthenticationType;
        console.log(this.state.towStepAuthenticationType);
        await this.$router.push({ name: "OtpLogin" });
      }
      // else if (res.token) {
      //   this.$setToken(res.token, this.state.userInfo.remember)
      //   await this.$router.push('/profile')
      // }
    },
  },
  mounted() {
    this.data.osType = this.$detectOs();
    this.data.browser = this.$detectBrowser() || "CHROME";
    this.data.deviceType = this.$detectDevice();

  },

  created: function () {
    window.addEventListener("click", (event) => {
      if (!event.target.closest(".input")) {
        this.femail = false;
        this.fpass = false;
        this.emailWarn = "";
      }
    });
  },
  beforeDestroy() {
    window.removeEventListener("click", (event) => {
      if (!event.target.closest(".input")) {
        this.femail = false;
        this.fpass = false;
        this.emailWarn = "";
      }
    });
  },

};
</script>

<style lang="scss" scoped>
.active {
  background: #dedede !important;
  border-radius: 4px;
  color: var(--secondary);
}
.btns {
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
  align-self: center;
  justify-content: center;
  background: #f8f8f8;
  box-shadow: -1px 4px 8px -1px rgba(192, 222, 234, 0.2);
  border-radius: 4px;
  width: 100%;
  height: 48px;
  padding: 6px 14px;
  button {
    background: transparent;
    flex: 1;
    height: 100%;
    max-height: 36px;
  }
}
.label-container {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  .warn {
    color: var(--red);
    font-weight: 300;
    font-size: clamp(10px, 1vw, 12px);
  }
  .svg-active {
    fill: var(--secondary);
  }
  .svg-error {
    fill: var(--red) !important;
  }
  .div-active {
    opacity: 1 !important;
    box-shadow: inset 0px -2px 0px var(--secondary);
  }
  .div-error {
    opacity: 1 !important;
    box-shadow: inset 0px -2px 0px var(--red);
  }
  .input {
    transition: 700ms;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 4px;
    align-items: center;
    padding: 14px 16px;
    border-radius: 8px 8px 0px 0px;
background: #F0F0F0;
    width: 100%;
    opacity: 0.7;
    max-height: 50px;
    &:hover {
      opacity: 1;
    }
    .svg {
      width: 20px;
      height: 16px;
    }
    label {
      width: 90%;
      display: flex;
      flex-direction: column;
      row-gap: 3px;
      position: relative;
      z-index: 1;
      input {
        transform: translateY(10px);
        background: transparent;
        z-index: 3;
        color: var(--black);
        font-weight: 400;
        font-size: clamp(14px, 2vw, 16px);
      }
      span {
        position: absolute;
        color: var(--a-hover);
        font-weight: 400;
        font-size: clamp(14px, 2vw, 16px);
        z-index: 2;
        transition: 700ms;
      }
      .span-active {
        transform: translate(15px, -11px) scale(0.7, 0.7);
        color: var(--secondary) !important;
      }
      .span-error {
        transform: translate(15px, -11px) scale(0.7, 0.7);
        color: var(--red) !important;
      }
    }
  }
}

.inputs-container {
  display: flex;
  flex-direction: column;
  row-gap: 23px;
  width: 100%;
}

.register-container {
  width: 100%;
  max-width: 460px;

  background: var(--homepage-bg);
  box-shadow: 0px 0px 8px -1px rgba(65, 70, 192, 0.2);
  border-radius: 12px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  align-items: flex-start;
  h2 {
    align-self: center;
  }
  button {
    width: 100%;
    align-self: center;
    height: 44px;
    display: flex;
    justify-content: center;
    gap: 4px;
    align-items: center;
  }
  .or {
    align-self: center;
  }
}
</style>