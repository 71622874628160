<template>
<div class="modal-backdrop" @click.prevent="$emit('close')">
  <div class="modal">
<p class="small padding">فیلتر بر اساس:</p>
    <div class="list d-flex flex-col gap-xss">
      <span class="d-flex justify-space-between items hover pointer" v-for="(item,index) in coins" :key="index" @click="selectCoin(item)">
        <span class="margin">{{item.persianName}}</span>
        <img class="margin" :src="`/coins/${item.coin}.png`" alt="" width="24" height="24">
      </span>
    </div>


  </div>
</div>
</template>

<script>
export default {
  name: "HistoryModal",
  data(){
    return{
      fsearch: false,
      regex: '',
      coins: [],
      coin: null,
      paymentType:'TOMAN',
    }
  },
  methods:{
    async getCoins() {
      this.state.loading = true;
      let res = await this.$axios.get("/coins/details");
      res = res.content.filter((name) => name.coin !== "TOMAN");
      console.log(res)
      this.coins=res;
      this.state.loading = false;
    },
    selectCoin(item){
this.state.searchCoin = item.coin;
    }
    // searchCoin() {
    //   return this.coins.filter((coin) => {
    //     console.log(this.regex);
    //     console.log(coin);
    //     if (coin.code.toUpperCase().includes(this.regex.toUpperCase()) || coin.persianName.includes(this.regex) || coin.englishName.toUpperCase().includes(this.regex.toUpperCase()) || coin.coin.toUpperCase().includes(this.regex.toUpperCase())) {
    //
    //       return coin;
    //     }
    //   });
    // },
  },
  mounted() {
    this.getCoins()
  }
}
</script>

<style lang="scss" scoped>
.padding{
  padding: 0 1em;
}
.margin{
  margin: 0 1em;
}

.modal{
  display: flex;
  flex-direction: column;
  background: var(--white);
  padding: 1.5em 0;
  border-radius: 1em;
  gap: 2em;
  width: 100%;
  max-width: 270px;
}
.list{
  max-height: 200px;
  //padding: 0 1em;
  overflow: scroll;
}
.items{
  min-height: 40px;
  align-items: center;
}
</style>