import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './Routes';
import Meta from 'vue-meta'

Vue.use(VueRouter);


const that = Vue.prototype

Vue.use(Meta, {
    keyName: 'metaInfo',
    attribute: 'data-vue-meta',
    ssrAttribute: 'data-vue-meta-server-rendered',
    tagIDKeyName: 'vmid',
    refreshOnceOnNavigation: true
})

const router = new VueRouter({
    mode : 'history',
    base : process.env.BASE-URL,
    routes,
    scrollBehavior() {
        return {x: 0, y: 0};
    },
})


// const that = Vue.prototype;


const forLogin = [
    'Dashboard',
    'Activeorders',
    'Account',
    'Wallet',
    'History',
    'Setting',
    'Notifications',
    'Invitefriends',
    'Tickets',
    'Exchange',
    'Advancetrade',
    ]
    const unAuthorUser = [
    'History',
    'Dashboard',
    'Notifications',
    'Tickets',
    'Setting',
    'Account',
    'Activeorders',
    'Wallet',
    // 'Exchange',
    ]
    const otp = [
    'Otp',
    'registerOTP',

    ]
    const auth = [
    'Login',
    'Signup',
    'Forgotpassnew',
    ];

    router.beforeEach(async (to, from, next) => {

        // document.title = to.name

        that.state.loading = true
        if(!that.state.coins){
            await that.$getCoins()
          }
        if (that.state.token) {

            if (!that.state.gotten) {
                await that.$getuserInfo()
                that.state.loading = true
                await that.$getFees()
            }

            that.state.loading = true

            // if (from.name === 'Blog') {
            //     await window.open(to.fullPath, '_self')
            // }
            // else
            if (otp.includes(to.name) || auth.includes(to.name)) {
                next({name: 'Dashboard'})
            }
            else
            if(to.name === 'Advancetrade') {
                try {
                    that.state.loading = true
                    await that.$axios(`/markets/${to.params.tradeFrom}_${to.params.tradeTo}`)
                    next()
                } catch (e) {
                    if (e.response.data.message === "MARKET_IS_NOT_ACTIVE") {
                        if (otp.includes(from.name)) {
                            next({name: 'Homepage'})
                        } else {
                            await that.$error('بازار غیرفعال است')
                            next(from)
                        }
                    }
                }
            }
            else {
                if (that.state.userInfo.authenticationType ==="AUTHORIZED") {
                    next()
                } else {
                    if (otp.includes(from.name) && to.name !== 'Validation') {
                        next({name: 'Validation'})
                    } else if (unAuthorUser.includes(to.name)) {
                        that.state.loading = false
                        const res = await that.$error('عدم دسترسی', 'برای دسترسی به این بخش نیاز است ابتدا حساب شما تکمیل و تایید شود', 'error', 'حساب کاربری')
                        if (res.isConfirmed) {
                            next({name: 'Validation'})
                        } else {
                            next(from)
                        }
                    } else next()
                }
            }
        }
        else {
            if ((otp.includes(to.name) && !Vue.$cookies.isKey('username')) || forLogin.includes(to.name))
                next({name: 'Login'})
            else {
                next()
            }
        }
        that.state.loading = false
    })

    
    
    export default router