<template>
  <div class="history d-flex flex-col">
    <div class="d-flex header justify-space-between flex-wrap">
      <div class="titles d-flex bolder align-items-center align-items-end flex-wrap relative">
        <span class="regular gray" @click.prevent="select='active'" :class="{'active': select==='active'}"
              v-if="$route.path.includes('trade')"> سفارشات فعال</span>
        <span class="regular gray" @click.prevent="select='order'"
              :class="{'active': select==='order'}">تاریخچه سفارشات</span>
        <span class="regular gray" @click.prevent="select='trade'"
              :class="{'active': select==='trade'}">تاریخچه معاملات</span>
        <span class="regular gray" @click.prevent="select='crypto'" :class="{'active': select==='crypto'}"
              v-if="$route.name.includes('History')">تراکنش ارزی</span>
        <span class="regular gray" @click.prevent="select='rial'" :class="{'active': select==='rial'}"
              v-if="$route.name.includes('History')">تراکنش   تومانی</span>
        <span class="regular gray" @click.prevent="select='login'" :class="{'active': select==='login'}"
              v-if="$route.name.includes('History')">تاریخچه ورود به سایت</span>





      </div>
      <div class="d-flex align-items-center gap-xss small gray relative">
        <div class="d-flex align-self-center" v-show="select==='crypto' || select==='rial'">
          <button class="small gray" @click.prevent="drop =! drop">
            بر اساس:
            {{transaction}}</button>
          <div class=" list d-flex flex-col gap-xss small gray align-self-center" v-show="drop">
            <span v-for="(item,index) in transactionType" :key="index" @click="drop=!drop;transaction=item">{{item}}</span>
          </div>
        </div>
        <div class="d-flex align-self-center" v-show="select==='order' || select==='trade'">
          <button class="small gray" @click.prevent="drop =! drop">
            بر اساس:
            {{market}}</button>
          <div class=" list d-flex flex-col gap-xss small gray align-self-center" v-show="drop">
            <span v-for="(item,index) in marketType" :key="index" @click="drop=!drop;market=item">{{item}}</span>
          </div>
        </div>
        <img class="pointer" src="../../../assets/Icons/filter.png" alt="" width="38" height="38"
             v-if="$route.name.includes('History')&&select !=='login'"
        @click="modal =! modal"
        >
      </div>

    </div>
    <div class="tables">
      <ActiveOrdersVue v-if="select==='active'" />
      <OrderHistory v-if="select==='order'" :marketType="market" :key="market  + state.searchCoin"/>
      <TradeHistory v-if="select==='trade'" :marketType="market" :key="market  + state.searchCoin"/>
      <CryptoTrans v-if="select==='crypto'" :transactionType="transaction" :key="transaction + state.searchCoin"/>
      <RialTrans v-if="select==='rial'" :transactionType="transaction" :key="transaction + state.searchCoin"/>
      <LoginHistory v-if="select==='login'"/>


    </div>
    <HistoryModal @close="modal=!modal" v-if="modal"/>
  </div>
</template>
<script>
import OrderHistory from "@/components/Panel/History/OrderHistory.vue";
import TradeHistory from "@/components/Panel/History/TradeHistory.vue";
import CryptoTrans from "@/components/Panel/History/CryptoTrans.vue";
import RialTrans from "@/components/Panel/History/RialTrans.vue";
import LoginHistory from "@/components/Panel/History/LoginHistory.vue";
import ActiveOrdersVue from "./ActiveOrders.vue";
import HistoryModal from "@/components/Panel/History/HistoryModal";

export default {
  name: "History",
  components: {HistoryModal, LoginHistory, RialTrans, TradeHistory, OrderHistory, CryptoTrans, ActiveOrdersVue},
  data() {
    return {
      select: 'order',
      transactionType: ['واریز', 'برداشت'],
      transaction:'',
      marketType:['خرید', 'فروش'],
      market:'خرید',
      drop:false,
      modal:false,
    };
  },
  mounted() {
    this.transaction = this.transactionType[0];
  }
}

</script>
<style lang="scss" scoped>
.active {
  color: var(--secondary);
  border-bottom: 2px solid var(--secondary);

}
.list{
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: .5rem;
  top: 4em;
  width: 100px;
  background: var(--white);
padding: 1em;
  z-index: 3;
}
.titles {
  gap: 3rem;
  font-weight: bolder;
  align-items: flex-end !important;
  cursor: pointer;

  span {
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.header {
  background: #FDFDFD;
  border-radius: 8px 8px 0px 0px;
  align-items: center;
  padding: 0 1rem;


}

@media(max-width: 500px) {
  .header {
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
    padding: 5px;

    img {
      align-self: flex-end;
    }
  }
  .titles {
    width: 100%;
    flex-direction: column;
    align-items: flex-start !important;
    gap: 1rem;
  }
}
</style>