<template>
  <div class="container" :class="{'tables':$route.path.includes('trade')}">
    <table>
      <tr class="head gray">
        <td> بازار </td>
        <td class="remove"> زمان و تاریخ </td>
        <td class="remove2"> سمت </td>
        <td class="remove"> نوع </td>
        <td > قیمت واحد </td>
        <td> مقدار </td>
        <td class="remove"> کارمزد </td>
        <td class="remove2"> مبلغ کل </td>
        <td class="remove"> پر شده </td>
        <td > وضعیت </td>
      </tr>
<perfect-scrollbar @ps-y-reach-end="page++;getOrders">
  <tbody>
  <tr v-for="(item,index) in coins" :key="index" class="body hover">
    <td class="rtl"> {{
        item.orderType.includes('BUY') ? ($coins[item.destinationCoin].persianName + '-' + $coins[item.sourceCoin].persianName) : ($coins[item.sourceCoin].persianName + '-' + $coins[item.destinationCoin].persianName)
      }}
    </td>
    <td> {{$G2J(item.createdAtDateTime)}} </td>
    <td class="remove" :class="item.orderType.includes('BUY') ? 'green':'red'" > {{item.orderType.includes('BUY') ? ' خرید ':' فروش '}} </td>
    <td class="remove" v-if="item.orderType.includes('LIMITED')" > حد </td>
    <td class="remove" v-if="item.orderType.includes('MARKET')" > بازار </td>
    <td class="remove" v-if="item.orderType.includes('EXCHANGE')" > صرافی
    <td class="remove" v-if="item.orderType.includes('STOP')" > حد توقف </td>
    <td class="remove2"> {{$toLocal(item.unitPrice)}} </td>
    <td> {{$toLocal(item.amount,$decimal[item.sourceCoin])}} </td>
    <td class="remove"> {{item.wagePercent}}% </td>
    <td> {{$toLocal(item.wholePrice)}} </td>
    <td class="remove"> {{item.executedAmount}}% </td>
    <td v-if="item.orderStatus.includes('FINISHED')" class="remove2 green"> تکمیل </td>
    <td v-if="item.orderStatus.includes('CANCELED')" class="remove2 red"> لغو شده </td>
    <td v-if="item.orderStatus.includes('EXPIRED')" class="remove2 yellow"> انقضا </td>
    <td v-if="item.orderStatus.includes('OPEN')" class="remove2 green"> باز </td>
  </tr>
  </tbody>
</perfect-scrollbar>
    </table>
  </div>
</template>

<script>
export default {
  name:'OrderHistory',
  props:['marketType'],
  data() {
    return {
      coins:[],
page:1,
      lastPage:1,
    }
  },
  methods:{
    async getOrders(){
if(this.page<=this.lastPage){
  let res = await this.$axios.get('/orders',{
    size:5,
    page:this.page
  });
  res = res.content
  this.marketType==='خرید'? this.coins = res.filter(item=>item.orderType.includes("BUY")) : this.coins = res.filter(item=>item.orderType.includes("SELL"))
}
    },

  },
  mounted() {
  this.getOrders()
  }
}
</script>

<style lang="scss" scoped>
tbody,tr{
  width:100%;
  display: table;
}
.ps{
  max-height: 400px;
}
.container{
  display: flex;
  width: 100%;
  max-width: none !important;
  background: #FAF9F7;
  border-radius: 8px;
  padding: 20px;
}
.fail-btn{
  background: var(--red);
  color: var(--white);
  font-weight: 500;
  font-size: clamp(8px,1vw,12px);
}
table{
  width: 100%;
  td:first-child{
    text-align: right;
  }
  td:last-child{
    text-align: left;
  }

  td{
    padding: 0 10px;
  }
  .head{
    position: sticky;
    top: 0;
    background: var(--darker-white);
    td{

      height: 56px;
      color:#A1A1AC;
    }
  }
  td{
    width: 10%;
    text-align: center;
    height: 35px;
    font-weight: 500;
    font-size: clamp(8px,1vw,12px);
  }
}

.body{
  td{
    height: 56px;
    border-bottom: 1px solid var(--gray-lighter);
  }
}
@media only screen and(max-width:900px){
  .remove{
    display: none;
  }
}
@media only screen and(max-width:600px){
  .remove2{
    display: none;
  }
}

.tables{
  max-height: 300px;
  min-height: 300px;
  overflow: auto;
}

</style>