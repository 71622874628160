<template>
  <div class="forms-container">

    <Register v-if="$route.name == 'Register'" />
    <Login v-if="$route.name == 'Login'" />
    <registerOTP v-if="$route.name == 'RegisterOTP'" />
    <Otp v-if="$route.name == 'OtpLogin'"/>
    <ForgetPass v-if="$route.name=='ForgetPass'"/>
    <NewPass v-if="$route.name == 'NewPass'"/>
<RegisterMobile v-if="$route.name == 'RegisterMobile'" />


  </div>
</template>

<script>
import Register from "./Register.vue";
import Login from "./Login.vue";
import registerOTP from "./registerOTP.vue";
import Otp from "./Otp.vue";
import ForgetPass from "./ForgetPass.vue";
import NewPass from "./NewPass.vue";
import RegisterMobile from "@/components/Auth/RegisterMobile";
export default {
  name: "AuthenticationsFroms",
  components: { Register, Login, registerOTP, Otp, ForgetPass, NewPass ,RegisterMobile},
};
</script>

<style lang="scss" scoped>
.forms-container {
  background-image: url("../../assets/panel/login-bg.png");
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-position-x: center;
background-size: contain;
z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
//&:before{
//  background-image: url("../../assets/panel/login-bg.png");
//  background-position-y: bottom;
//  background-repeat: no-repeat;
//  background-size: cover;
//  content: '';
//  position: absolute;
//  top: 0;
//  left: 0;
//  width: 100%;
//  height: 100%;
//
//}
}
.bg{
  position: fixed;
  max-height: 400px;
bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}



@media (max-width:500px){
    .forms-container{
        padding: 1rem;
        margin-top: 3.5em;
    }
  .bg{
    display: none;
  }
}
</style>