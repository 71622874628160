<template >
  <div class="navbar-container" v-if="!$route.path.includes('Dashboard')">
    <transition name="ham-fade" >
      <div  class="ham-menu" v-if="hamMenuShow">
        <a @click.prevent="$router.push('Dashboard/trade')" href="" class="item"> معامله حرفه ای </a>
        <a @click.prevent="$router.push('/Dashboard/otc')" href="" class="item"> معامله سریع (OTC) </a>
        <a @click.prevent="$router.push('/market')" href="" class="item"> قیمت رمز ارز ها </a>
        <a @click.prevent="$router.push('/fee')" href="" class="item"> کارمزد و سطوح کاربری </a>
        <a @click.prevent="$router.push('/auth/login')" href="" class="item"  v-if="!state.token"> ورود </a>
        <a @click.prevent="$router.push('/auth/register')" href="" class="item" v-if="!state.token"> عضویت </a>
        <a @click.prevent="$router.push('/Dashboard')" class="item">ورود به حساب</a>
      </div>
    </transition>
    <div class="logo-items">
      <div class="logo">
        <div @click="hamMenuShow = !hamMenuShow" class="hambtn-container">
          <span :class="{'top':hamMenuShow}" class=""></span>
          <span :class="{'remove':hamMenuShow}" ></span>
          <span :class="{'bottom':hamMenuShow}" class=""></span>
        </div>
         <img @click.prevent="$router.push('/')" src="../../assets/lil-logo.svg" alt="" width="80" height="30">
        <span class="logo-span">  </span>
      </div>
      <div class="nav-items">
        <a @click.prevent="$router.push('/Dashboard/trade')" href="" class="item"> معامله حرفه ای </a>
        <a @click.prevent="$router.push('/Dashboard/otc')" href="" class="item"> معامله سریع (OTC) </a>
        <a @click.prevent="$router.push('/market')" href="" class="item"> قیمت رمز ارز ها </a>
        <a @click.prevent="$router.push('/fee')" href="" class="item"> وبلاگ </a>
      </div>
    </div>
    <div class="btns">
      <transition name="drop-fade" >
        <div v-if="showDropDown" class="dropdown-container">
          <div @click="reDirect('Dashboard')" class="drop-item">
            <img src="../../assets/Icons/dashIcon.svg" alt="">
            <span> داشبورد </span>
          </div>
          <div @click="reDirect('Wallet')" class="drop-item">
            <img src="../../assets/Icons/walletIcon.svg" alt="">
            <span> کیف پول </span>
          </div>
          <div @click="reDirect('History')" class="drop-item">
            <img src="../../assets/Icons/historyIcon.svg" alt="">
            <span> تاریخچه </span>
          </div>
          <div @click="reDirect('Account')" class="drop-item">
            <img src="../../assets/Icons/profileIcon.svg" alt="">
            <span> پروفایل </span>
          </div>
          <div @click="reDirect('Settings')" class="drop-item">
            <img src="../../assets/Icons/settingsIcon.svg" alt="">
            <span> تنظیمات </span>
          </div>
          <div @click="reDirect('InviteFriends')" class="drop-item">
            <img src="@/assets/Icons/inviteIcon.svg" alt="">
            <span> دعوت </span>
          </div>
          <div @click="reDirect('Exit')" class="drop-item">
            <img src="@/assets/Icons/exitIcon.svg" alt="">
            <span class="exit-span"> خروج </span>
          </div>
        </div>
      </transition>
      <inline-svg v-if="state.token"  :src="require('../../assets/panel/support-icon.svg')" class="icon"   @click.prevent="$router.push({name:'Support'})" />
        <img v-if="state.token" src="@/assets/Icons/User.svg" alt="" @click.prevent ="$router.push('/Dashboard')">
      <div class="notifalert" v-if="state.token">
        <img v-if="state.token" src="@/assets/panel/notification-icon.svg" alt="">
        <span v-if="notifs>0" class="notif-num"> {{notifs}} </span>
      </div>

      <div  style="cursor:pointer" @click="showDropDown = !showDropDown" v-if="state.userInfo.firstName" class="name-forms-btns">
        <img v-if="state.token" src="@/assets/Icons/profileIcon.svg" alt="">

        <span class="name" v-if="state.token"> {{state.userInfo.firstName}} {{state.userInfo.lastName}} </span>
      </div>




      <div v-if="!state.userInfo.fullName" class="name-forms-btns">
        <div  class="forms-btns" v-if="!state.token">
          <a class="secondary" @click.prevent="$router.push('/auth/login')" href=""> ورود </a>
          <span> | </span>
          <a class="secondary" @click.prevent="$router.push('/auth/register')" href=""> عضویت </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'Navbar',
  data() {
    return {
      hamMenuShow:false,
      notifs:5,
      showDropDown:false,
    }
  },
  methods:{
    reDirect(e){
      this.showDropDown=false;
      if(e=='Exit'){
        this.$logout();
      }else{
        this.$router.push({name:e});
      }

    }
  },
  created : function(){
    window.addEventListener("click" , (event) => {
      if(!event.target.closest('.dropdown-container,.name-forms-btns')){
        this.showDropDown=false
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.icon{
  stroke: var(--black);
}
.item{
  color: var(--black);
}
.dropdown-container{
  box-shadow: 0px 0px 8px -1px rgba(65, 70, 192, 0.2);
  background: rgba(240, 240, 245, 1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 24px;
  padding: 24px 36px;
  border-radius: 12px;
  position: absolute;
  top: 60px;
  left: 0;
  z-index: 9000 !important;
  overflow: hidden;
  .drop-item{
    display: flex;
    flex-direction: row;
    column-gap: 16px;
    align-items: center;
    opacity:.7 ;
    color: var(--grey-light);
    cursor: pointer;
    transition: 400ms;
    &:hover{
      opacity: 1;
    }
    .exit-span{
      color: var(--red);
    }
  }
}
.ham-menu{
  z-index: 100;
  position: fixed;
  right: 0;
  top: 0;
  width: 30%;
  display: flex;
  flex-direction: column;
  padding: 60px 10px;
  background: var(--gray-light);
  height: 100vh;
  align-items: center;
  text-align: center;
  row-gap: 30px;
}
.remove{
  display: none;
}
.top{
  transform: rotate(45deg) translateX(6px);
}
.bottom{
  transform: rotate(-45deg) translateX(6.5px);
}
.hambtn-container{
  z-index: 101;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 5px;
  span{
    width: 25px;
    border-radius: 12px;
    border: 2px solid var(--black);
    transition: 600ms;
  }
}
.navbar-container{
  position: fixed;
  z-index: 80;
  top: 0;
  padding: 18px 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background: linear-gradient(9.14deg, rgba(254, 252, 243, 0.4) -6.03%, rgba(251, 252, 236, 0.4) 95.73%);
  opacity: 0.95;
  backdrop-filter: blur(70px);
  .logo-items{
    font-weight: 400;
    font-size: clamp(14px,2vw,16px);
    display: flex;
    flex-direction: row;
    column-gap: 70px;
    // justify-content: space-between;
    // column-gap: 24px;
    // width: 55%;
    align-items: center;
    .logo{
      align-items: center;
      display: flex;
      flex-direction: row;
      column-gap: 12px;
      width: 10%;
      //img{
      //  width: 26px;
      //  height: 26px;
      //}
    }
    .nav-items{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      column-gap: 24px;
    }
  }
  .btns{
    display: flex;
    flex-direction: row;
    column-gap: 36px;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    .notifalert{
      display: flex;
      justify-content: center;
      align-items: center;
      .notif-num{
        width: 14px;
        height: 14px;
        background: var(--red);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        transform: translate(25px,-5px);
        color: white;
        font-size: clamp(10px,1vw,12px);
        font-weight: 400;
      }
    }
    .name-forms-btns{
      display: flex;
      flex-direction: row;
      column-gap: 12px;
    }
  }
}
@media only screen and(max-width:1000px){
  .nav-items{
    display: none !important;
  }
  .logo-items{
    column-gap: 24px !important;
  }
}
@media only screen and(max-width:500px){
  .forms-btns{
    display: none !important;
  }
}
@media only screen and(max-width:800px){
  .hide{
    display: none !important;
  }
  .ham-menu{
    width: 50%;
  }
  .btns{
    width: 55%;
  }
}
@media only screen and(max-width:500px){
  .ham-menu{
    width: 70%;
  }
  .name{
    display: none;
  }
}
@media only screen and(min-width:1000px){
  .hambtn-container{
    display: none;
  }
  .ham-menu{
    display: none;
  }
}

</style>