<template>
  <div class="container d-flex flex-col" :class="{'tables':$route.path.includes('trade')}">
    <div class=""></div>
    <table>
      <tr class="head">
        <td class="remove"> زمان و تاریخ</td>
        <td class="remove"> مبلغ کل</td>
        <td class="remove2"> شبکه</td>
        <td class="remove"> شماره شبا</td>
        <td> شناسه تراکنش</td>
        <td class="remove2"> وضعیت</td>
      </tr>



      <perfect-scrollbar
          ref="scroll" @ps-y-reach-end="page++;pageW++;getData">
        <tbody>
        <tr v-for="(item,index) in dataTable " :key="index" class="body hover">
          <td> {{
              $G2J(item.createdAtDateTime)
            }}
          </td>
          <td class="remove"> {{
              $toLocal(item.amount, 0)
            }}
          </td>
          <td class="remove"> {{
              item.tokenType === null ? '-' : item.tokenType
            }}
          </td>
          <td class="remove2"> {{
              item.destinationWalletAddress
            }}
          </td>
          <td> {{
              item.hashCode === "" ? '_' : item.hashCode
            }}
          </td>
          <td class="remove" :class="item.transactionStatus.includes('RAISED_BY_USER') ? 'red':'green'"> {{
              item.transactionStatus.includes('PENDING') ? ' موفق ' : ' ناموفق '
            }}
          </td>
        </tr>
        </tbody>
      </perfect-scrollbar>

    </table>
  </div>
</template>

<script>
export default {
  name: 'OrderHistory',
  props: ['transactionType'],
  data() {
    return {
      listTables: [
        {
          bankName: 'ملی',
          destinationWallet: 'vswvdv54ev4sz2vde4vs4xzc1vvewsd4x',
          destinationSheba: '1234567891234567',
          hashCode: '4134125326',
          net: 'TRC20',
          amount: 0.003,
          sourceCoin: 'BITCOIN',
          marketType: 'BITCOIN_TOMAN',
          orderType: 'MARKET_BUY',
          receivedAmount: 89.685,
          wholePrice: 12345678,
          createdAtDateTime: "2022-06-01T15:10:35",
          unitPrice: 30000,
          wagePercent: 0.35,
          situation: 'COMPLETED'
        },
        {
          bankName: 'ملی',
          destinationWallet: 'vswvdv54ev4sz2vde4vs4xzc1vvewsd4x',
          destinationSheba: '1234567891234567',
          hashCode: '4134125326',
          net: 'TRC20',
          amount: 0.003,
          sourceCoin: 'BITCOIN',
          marketType: 'BITCOIN_TOMAN',
          orderType: 'MARKET_BUY',
          receivedAmount: 89.685,
          wholePrice: 12345678,
          createdAtDateTime: "2022-06-01T15:10:35",
          unitPrice: 30000,
          wagePercent: 0.35,
          situation: 'CANCELED'
        },
        {
          bankName: 'ملی',
          destinationWallet: 'vswvdv54ev4sz2vde4vs4xzc1vvewsd4x',
          destinationSheba: '1234567891234567',
          hashCode: '4134125326',
          net: 'TRC20',
          amount: 0.003,
          sourceCoin: 'BITCOIN',
          marketType: 'BITCOIN_TOMAN',
          orderType: 'MARKET_BUY',
          receivedAmount: 89.685,
          wholePrice: 12345678,
          createdAtDateTime: "2022-06-01T15:10:35",
          unitPrice: 30000,
          wagePercent: 0.35,
          situation: 'EXPIRED'
        },
        {
          bankName: 'ملی',
          destinationWallet: 'vswvdv54ev4sz2vde4vs4xzc1vvewsd4x',
          destinationSheba: '1234567891234567',
          hashCode: '4134125326',
          net: 'TRC20',
          amount: 0.003,
          sourceCoin: 'BITCOIN',
          marketType: 'BITCOIN_TOMAN',
          orderType: 'MARKET_BUY',
          receivedAmount: 89.685,
          wholePrice: 12345678,
          createdAtDateTime: "2022-06-01T15:10:35",
          unitPrice: 30000,
          wagePercent: 0.35,
          situation: 'CANCELED'
        },
        {
          bankName: 'ملی',
          destinationWallet: 'vswvdv54ev4sz2vde4vs4xzc1vvewsd4x',
          destinationSheba: '1234567891234567',
          hashCode: '4134125326',
          net: 'TRC20',
          amount: 0.003,
          sourceCoin: 'BITCOIN',
          marketType: 'BITCOIN_TOMAN',
          orderType: 'MARKET_BUY',
          receivedAmount: 89.685,
          wholePrice: 12345678,
          createdAtDateTime: "2022-06-01T15:10:35",
          unitPrice: 30000,
          wagePercent: 0.35,
          situation: 'EXPIRED'
        },
        {
          bankName: 'ملی',
          destinationWallet: 'vswvdv54ev4sz2vde4vs4xzc1vvewsd4x',
          destinationSheba: '1234567891234567',
          hashCode: '4134125326',
          net: 'TRC20',
          amount: 0.003,
          sourceCoin: 'BITCOIN',
          marketType: 'BITCOIN_TOMAN',
          orderType: 'MARKET_BUY',
          receivedAmount: 89.685,
          wholePrice: 12345678,
          createdAtDateTime: "2022-06-01T15:10:35",
          unitPrice: 30000,
          wagePercent: 0.35,
          situation: 'CANCELED'
        },
        {
          bankName: 'ملی',
          destinationWallet: 'vswvdv54ev4sz2vde4vs4xzc1vvewsd4x',
          destinationSheba: '1234567891234567',
          hashCode: '4134125326',
          net: 'TRC20',
          amount: 0.003,
          sourceCoin: 'BITCOIN',
          marketType: 'BITCOIN_TOMAN',
          orderType: 'MARKET_BUY',
          receivedAmount: 89.685,
          wholePrice: 12345678,
          createdAtDateTime: "2022-06-01T15:10:35",
          unitPrice: 30000,
          wagePercent: 0.35,
          situation: 'EXPIRED'
        },
      ],
      tableContents: [],
      deposits: [],
      withdraws: [],
      page: 1,
      lastPage: 1,
      pageW: 1,
      lastPageW: 1
    }
  },
  computed: {
    dataTable() {
      let data;
      if (this.transactionType == 'واریز') {
        data = this.deposits
      } else {
        data = this.withdraws;
      }
      console.log(data);
      return data;
    },
    //  getData(){
    //   let data;
    //   if(this.transactionType=='واریز'){
    //     data=this.getDeposits()
    //   }
    //   else{
    //     data=this.getWithdraws();
    //   }
    //   console.log(data);
    //   return data;
    //
    // }

  },
  methods: {
    async getDeposits() {
      if (this.page <= this.lastPage) {

        const res = await this.$axios('/wallets/deposit', {
          params: {
            relatedCoin: 'TOMAN',
            size: 10,
            page: this.page
          }
        })
        if (res) {
          this.lastPage = res.totalPages;
          this.deposits = [...this.deposits, ...res.content]

        }
      }

    },
    async getWithdraws() {
      if (this.pageW <= this.lastPageW) {

        const res = await this.$axios('/wallets/withdrawal-requests', {
          params: {
            relatedCoin: 'TOMAN',
            size: 10,
            page: this.pageW
          }
        })
        if (res) {
          this.lastPageW = res.totalPages;
          this.withdraws = [...this.withdraws, ...res.content]

        }
      }

    },
    getData() {
      if (this.transactionType == 'واریز') {
        this.getDeposits()

      } else {
        this.getWithdraws();

      }
    }

  },
  async mounted() {
    await this.getDeposits();
    await this.getWithdraws();
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  width: 100%;
  max-width: none !important;
  background: #FAF9F7;
  border-radius: 8px;
  padding: 10px;
}

.fail-btn {
  background: var(--red);
  color: var(--white);
  font-weight: 500;
  font-size: clamp(8px, 1vw, 12px);
}

table {
  tbody, tr {
    width: 100%;
    display: table;
  }

  .ps {
    max-height: 100vh;
  }

  table-layout: fixed;

  td {
    height: 56px !important;
  }

  width: 100%;

  .head {
    td {
      height: 48px;
      color: #A1A1AC;
    }
  }

  td {
    width: 10%;
    text-align: center;
    height: 35px;
    font-weight: 500;
    font-size: clamp(8px, 1vw, 12px);
  }
}


@media only screen and(max-width: 900px) {
  .remove {
    display: none;
  }
}

@media only screen and(max-width: 600px) {
  .remove2 {
    display: none;
  }
}

.tables {
  max-height: 300px;
  min-height: 300px;
  overflow: auto;
}

</style>