<template>
<div class="modal-backdrop">

    <div class="section-loading">
<div class="list">
  <ul class="list-bars">
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>

  </ul>

</div>

    </div>


</div>
</template>

<script>
export default {
  name: "Loading"
}
</script>

<style lang="scss" scoped>
img{
  margin-right: 2%;
}
.list{
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-loading {
  //height: 100vh;
  //width: 100vw;
flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  //background-color: var(--darker-white);

}
.section-loading .text{
  align-self: center;
}
.section-loading ul.list-bars {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-loading ul.list-bars li {
  margin: 0 7px;
  width: 8px;
  border-radius: 10px;
  background-color: var(--secondary);
  animation: animate 0.8s infinite alternate;
}
.section-loading ul.list-bars li:nth-child(odd) {
  height: 80px;
  animation: animate 0.8s infinite alternate;
  animation-delay: 0.3s;
}
.section-loading ul.list-bars li:nth-child(even) {
  height: 20px;
  background-color: var(--primary);
}

@keyframes animate {
  from {
    height: 5px;
  }
  to {
    height: 70px;
  }
}



.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(111, 111, 112, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 110;
  gap: 9px;
}

.text{
display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center !important;
}
</style>