<template>
<div class="modal-backdrop" @click.self="$emit('close')">
  <div class="modal d-flex flex-col">
<h4>آیا از لغو سفارش اطمینان دارید؟</h4>
<div class="buttons d-flex w100 gap-xss justify-center">
  <button class="btn-green-outline" @click.prevent="deleteOrder">بله</button>
  <button class="btn-red-outline"  @click.prevent="$emit('close')" >خیر</button>

</div>

  </div>
</div>
</template>

<script>
export default {
  name: "DeleteOrder",
  props:{
    id:{
      type: Number,
      required: true
    }
  },
  methods:{
    async deleteOrder(){
      const id = this.id
      const res = await this.$axios.delete(`/orders/${id}`)
      res.message.includes('successful')? this.$emit('close')&& this.$error('لغو سفارش با موفقیت انجام شد'):this.$error(res.message)
    }
  }

}
</script>

<style lang="scss" scoped>
button{
  flex: 1 1 100px;
}
.modal{
  background: var(--white);
  border-radius: 8px;
  gap: 2em;

  padding: 1.5em;
}
</style>