import Vue from 'vue'
import App from './App.vue'
import router from './Router/Index';
import VueMeta from "vue-meta";
import './Styles/Main.scss';
import PerfectScrollbar from '@nima2142/vue2-perfect-scrollbar'
import '@nima2142/vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import VuePersianDatetimePicker from 'vue-persian-datetime-picker';
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import './Styles/Fonts/iran-sans.scss';
import InlineSvg from 'vue-inline-svg';
import TrendChart from "vue-trend-chart";
import VueCookies from 'vue-cookies'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.css';
import './Styles/SweetAlertStyle.css'
import './Lib/state';
import './Lib/axios';
import 'animate.css'
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import './Lib/globalConstants';
import './Lib/globalFunctions';
import FloatingVue from 'floating-vue'
import VueApexCharts from 'vue-apexcharts'


Vue.config.productionTip = false
Vue.use(VueCookies)
Vue.use(VueSweetalert2);
Vue.use(FloatingVue)
Vue.component('date-picker', VuePersianDatetimePicker);
Vue.component('inline-svg', InlineSvg);
Vue.component('apexchart', VueApexCharts);
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.use(LottieAnimation);
Vue.use(VueMeta)
Vue.component('l-marker', LMarker);

Vue.use(TrendChart);
Vue.use(PerfectScrollbar,{
  options:{
      // swipeEasing: false,
      wheelPropagation: false,
  }
});

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
