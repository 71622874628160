<template>
  <div class="register-container">
    <form class="form" @submit.prevent="$router.push('/Dashboard')">
      <div v-if="(state.towStepAuthenticationType = 'EMAIL')">

        <p>لطفا کد ارسال شده به ایمیل را وارد کنید.</p>
      </div>

      <div class="pass-container">
        <input
          ref="password"
          :type="type"
          placeholder="گذرواژه جدید"
          v-model="otp.password"
        />

        <img
          @click.prevent="showingPass"
          src="../../assets/Icons/password.svg"
          alt=""
        />
      </div>
      <div class="pass-container">
        <input
          ref="password"
          :type="typeRep"
          placeholder="تکرار گذرواژه جدید"
          v-model="repeat"
        />

        <img
          @click.prevent="showingPassRep"
          src="../../assets/Icons/password.svg"
          alt=""
        />
      </div>
      <div class="verification">
        <CodeInput
          class="code-input"
          @change="onChange"
          @complete="submit"
          :fields="num"
          ref="code-input"
        />
        <div class="timer">
          <div class="timer" v-if="finished">
            <a>ارسال مجدد</a>
          </div>
          <div class="timer" v-else>
            {{ display }}
          </div>
        </div>
      </div>
      <button
        class="btn-primary"
        id="btn"
        v-if="!finished"
        @click.prevent="$error('موفقیت امیز بود'),$router.push('/Dashboard')"
      >
        تایید
      </button>
      <button
        class="btn-primary"
        id="btn2"
        v-if="finished"
        @click.prevent="resetCode"
      >
        تلاش دوباره
      </button>
    </form>
  </div>

  <!--    <div class="icon">-->
  <!--      <InlineSvg-->
  <!--        :src="require('../../assets/login-signup/forget.svg')"-->
  <!--      ></InlineSvg>-->
  <!--    </div>-->
  <!--  </div>-->
</template>

<script>
// import TwoFactor from "../tools/TwoFactor.vue";
// // import InlineSvg from "vue-inline-svg";
// import Verification from "../tools/Verification.vue";
import CodeInput from "vue-verification-code-input";
import { DateTime, Duration } from "luxon";

export default {
  components: {
    CodeInput,
  },
  data() {
    return {
      type: "password",
      typeRep: "password",
      now: DateTime.local().set({ milliseconds: 0 }),
      end: DateTime.local().set({ milliseconds: 0 }).plus({ seconds: 5 }),
      tick: null,
      num: 6,
      passError: "",
      otp: {
        code: "",
        email: this.state.userInfo.email || this.$cookies.get("username") || "",
        mobile: this.state.userInfo.mobile || this.$cookies.get("mobile") || "",
        password: "",
      },
      repeat: "",
    };
  },
  watch: {
    now() {
      if (this.finished) {
        clearInterval(this.tick);
      }
    },
  },
  mounted() {
    // if (!this.$cookies.isKey("username")) {
    //   this.$router.push("/auth/login");
    // }
    if (this.pathMatch !== "google-auth") {
      this.now = DateTime.local().set({ milliseconds: 0 });
      this.end = DateTime.local()
        .set({ milliseconds: 0 })
        .plus({ seconds: 120 });
      this.tick = setInterval(() => {
        this.now = DateTime.local().set({ milliseconds: 0 });
      }, 1000);
    }

    this.tick = setInterval(() => {
      this.now = DateTime.local().set({ milliseconds: 0 });
    }, 1000);
  },
  computed: {
    remaining() {
      return this.end.diff(this.now).toObject();
    },
    display() {
      return Duration.fromObject(this.remaining).toFormat("mm:ss");
    },
    finished() {
      return this.now >= this.end;
    },
  },
  methods: {
    showingPass() {
      if (this.type === "password") {
        this.type = "text";
      } else if (this.type === "text") {
        this.type = "password";
      }
    },
    showingPassRep() {
      if (this.typeRep === "password") {
        this.typeRep = "text";
      } else if (this.typeRep === "text") {
        this.typeRep = "password";
      }
    },

    //repeat password Guard
    async checkPassword() {
      if (!this.repeat || !this.otp.password) {
        this.passError = "رمز اجباری می باشد";
        this.$error("", this.passError);
      } else if (this.repeat !== this.otp.password) {
        this.passError = "رمز های عبور باید یکی باشند";
        this.$error("خطای تکرار", this.passError);
      } else {
        if (this.otp.password.length < 8) {
          this.passError = "رمز حداقل باید 8 کارکتر باشد";
          this.$error("", this.passError);
        } else if (!this.$validPass(this.otp.password)) {
          this.passError = "رمز باید ترکیبی از حرف بزرگ و کوچک باشد";
          this.$error("", this.passError);
        } else {
          return (this.passError = "");
        }
      }
    },

    //check password Guard

    //submit code and password and email to BackEnd
    async submit() {
      await this.checkPassword();

      if (!this.passError) {
        this.state.loading = true;

        const res = await this.$axios.post("/users/reset-password/", this.otp);
        if (res) {
          this.$error("رمز عبور با موفقیت تغییر پیدا کرد", "", "success");
          await this.$router.push("/auth/login");
        }
        this.state.loading = false;
        await this.$router.push("/auth/login");
      }
    },

    onChange(e) {
      this.otp.code = e;
    },

    //reset password send again
    async resetCode() {
      this.state.loading = true;
      await this.$axios.post(
        `/users/resend-otp-code?email=${this.$cookies.get("username")}`
      );
      this.$error("کد جدید  " + "  ارسال شد.", "", "success");
      this.$cookies.set("username", this.state.userInfo.email);
      //codes that will reset our code
      if (this.now >= this.end) {
        this.now = DateTime.local().set({ milliseconds: 0 });
        this.end = DateTime.local()
          .set({ milliseconds: 0 })
          .plus({ seconds: 120 });
        this.tick = setInterval(() => {
          this.now = DateTime.local().set({ milliseconds: 0 });
        }, 1000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.register-container {
  width: 100%;
  max-width: 460px;
  background: var(white);
  box-shadow: 0px 0px 8px -1px rgba(65, 70, 192, 0.2);
  border-radius: 12px;
  padding: 24px;
  display: flex;
  flex-direction: column;

  row-gap: 50px;
  align-items: center;
  h2 {
    align-self: center;
  }
  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    button {
      width: 90%;
      height: 2.2em;
    }
  }
}

.forget {
  min-height: 86vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}



.pass-container {
  width: 100%;
  display: flex;
  background: #F0F0F0;
  border-radius: 8px 8px 0px 0px;
    height: 48px;
  justify-content: space-between;
  padding: 0 10px;
  align-items: center;
  input{
      background: transparent;
  }
}




</style>
