
<template>
  <form @submit.prevent="reD" class="register-container">
    <h2> تایید کد </h2>
    <div class="inputs-container">



            <span class="title">
               کد ارسالی به ایمیل خود را وارد کنید
            </span>




      <div class="show-email">
        <inline-svg :class="emailWarn? 'svg-error' : (femail? 'svg-active':'')" class="svg" :src="require('../../assets/Icons/Email.svg')" />
        <span class="email">
                    {{state.userInfo.email}}
                </span>
      </div>




      <CodeInput
          :loading="false"
          class="verif-code"
          v-on:change="onChange"
          @complete="submit"

      />
      <div class="timer">
        <div class="timer" v-if="finished">
          <a class="timer">0:00</a>
        </div>
        <div class="timer" v-else>
          {{ display }}
        </div>
      </div>

    </div>


    <div class="btns">
      <button v-if="!finished" :disabled="otp.code.length !== 6" class="submit btn-primary"> ثبت و ادامه </button>
            <button v-if="finished" class="submit btn-primary" @click.prevent="send"> ارسال مجدد</button>
    </div>


  </form>
</template>

<script>
import CodeInput from "vue-verification-code-input";
import {DateTime, Duration} from "luxon";
export default {
  name:'Otp',
  components:{
    CodeInput,

  },
  data() {
    return {
      now: DateTime.local().set({milliseconds: 0}),
      end: DateTime.local().set({milliseconds: 0}).plus({seconds: 120}),
      tick: null,
      num: 6,
      femail:false,
      otp: {
        code: '',
        email: this.state.userInfo.email || this.$cookies.get('username')
      }
    }
  },
  watch: {
    now() {
      if (this.finished) {
        clearInterval(this.tick);
      }
    },
  },
  computed: {
    remaining() {
      return this.end.diff(this.now).toObject();
    },
    display() {
      return Duration.fromObject(this.remaining).toFormat("mm:ss");
    },
    finished() {
      return this.now >= this.end;
    },
  },
  methods:{
    countDownTimer() {
      if (!this.finished) {
        this.timeOut = setTimeout(() => {
          this.countDown--;
          this.countDownTimer();
        }, 1000);
      } else {
        this.$error("زمان شما به اتمام رسید", "");
      }
    },
    async send() {
      this.now = DateTime.local().set({milliseconds: 0});
      this.end = DateTime.local()
          .set({milliseconds: 0})
          .plus({seconds: 120});

      this.tick = setInterval(() => {
        this.now = DateTime.local().set({milliseconds: 0});
      }, 1000);
      this.state.loading = true;

      await this.$axios.post(
          `/users/resend-otp-code?email=${this.$cookies.get("username")}`
      );
      // const text = {
      //     email: 'ایمیل',
      //     sms: 'شماره'
      // }
      this.$error(
          "کد جدید به " + this.otp.email + " شما ارسال شد.",
          "",
          "success"
      );
      this.$cookies.set("username", this.state.userInfo.email);
      if (this.countDown === 0) {
        this.countDown = 120;
        this.countDownTimer();
      }
    },
    onChange(v) {
      this.otp.code = v
    },

    async submit() {
      this.disablebtn = false;
      if (this.countDown !== 0) {
        this.state.loading = true;
        const res = await this.$axios.post("/users/check-otp-code", this.otp);
        if (res.token) {
          await this.$setToken(res.token);

          // this.state.userInfo.authorized ? await this.$router.push('/Dashboard'):await this.$router.push("/kyc");

          if(this.state.userInfo.authorized){
            await this.$router.push("/Dashboard");
          }
          else{
            await this.$router.push("/kyc");
          }


        }
      }
    },


    reD(){
      if(this.$route.name=='OtpRegister'){
        this.$router.push('/login')
      }else if(this.$route.name=='OtpLogin'){
        this.$router.push('/Dashboard')
      }else if(this.$route.name=='OtpForget'){
        this.$router.push('/newpass')
      }
    }
  },
  created:function(){
    window.addEventListener("click",event => {
      if(!event.target.closest('.label-container')){
        this.femail=false
        this.emailWarn=''
      }
    })
  },
  mounted() {
    this.countDownTimer();
    this.tick = setInterval(() => {
      this.now = DateTime.local().set({milliseconds: 0});
    }, 1000);
    if (!this.otp.email) {
      this.$router.push("/auth/login");
    }
    if (this.state.towStepAuthenticationType !== "GOOGLE_AUTHENTICATOR") {
      this.now = DateTime.local().set({milliseconds: 0});
      this.end = DateTime.local()
          .set({milliseconds: 0})
          .plus({seconds: 120});

      this.tick = setInterval(() => {
        this.now = DateTime.local().set({milliseconds: 0});
      }, 1000);
    }
  }

}
</script>

<style lang="scss" scoped>
.forgetpass{
  color: var(--primary);
  font-weight: 500;
  font-size: clamp(14px,2vw,16px);
}




.go-login{
  width: 100%;
  text-align: center;
  font-weight: 500;
  font-size: clamp(12px,1vw,14px);
  a{
    color: var(--primary);
  }
}




.btns{
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: 100%;
  align-items: center;
  .submit{
    width: 100%;
    height: 44px;
  }
  .google{
    width: 100%;
    background: none !important;
    color: var(--primary) !important;
    border: 1px solid var(--primary);
  }
}






.label-container{
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  .pass-svg{
    cursor: pointer;
  }
  .svg{
    width: 24px !important;
    height: 24px !important;
  }
  .warn{
    color: var(--red);
    font-weight: 300;
    font-size: clamp(10px,1vw,12px);
  }
  .svg-active{
    fill: var(--primary);
  }
  .svg-error{
    fill: var(--red) !important;
  }
  .div-active{

    opacity: 1 !important;
    box-shadow: inset 0px -2px 0px #6E78FF;
  }
  .div-error{
    opacity: 1 !important;
    box-shadow: inset 0px -2px 0px var(--red);
  }

}



.inputs-container{
  display: flex;
  flex-direction: column;
  row-gap: 23px;
  width: 100%;
  padding-bottom: 18px;
  .title{
    font-weight: 500;
    font-size: clamp(14px,2vw,16px);
    color: var(--black);
  }
  .show-email{
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 6px;
    .email{
      color: var(--black);
      font-weight: 400;
      font-size: clamp(10px,1vw,12px);
    }
  }
}



.register-container{
  //width: 100%;
  background: var(--darker-white);
  box-shadow: 0px 0px 8px -1px rgba(65, 70, 192, 0.2);
  border-radius: 12px;
  padding: 24px 12px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  align-items: flex-start;
  h2{
    color: var(--black);
    font-weight: 700;
    font-size: clamp(24px,3vw,28px);
    align-self: center;
  }
}
@media only screen and(max-width:1700px){
  .register-container{
    max-width: 500px;
  }
}
</style>