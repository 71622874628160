<template>
    <div class="market-container">
        <h3 v-if="$route.name=='Dashboard'"> بازار </h3>
        <div class="rows">
            <div v-if="$route.name=='AdvanceTrade'" class="trade-row">
                <span> رمز ارز </span>
                <span> قیمت واحد </span>
                <span> تغییرات </span>
            </div>
            <div  @click="$router.push('/Dashboard/trade/'+$route.params.tradeTo+'/'+$coin2Snake[coin.relatedCoin])"  v-for="(coin,index) in coins" :key="index" class="row hover">
               <div class="loader">

               </div>
                <div class="title">
                    <img :src="'/coins/'+ $coin2Snake[coin.relatedCoin] +'.png'" alt="" class="coin-badge ">
                    <div class="text">
                        <span class="faName"> {{$coinLabel[coin.relatedCoin]}} </span>
                        <span class="Unit"> {{coin.relatedCoin}} </span>
                    </div>
                </div> 
                <span class="price"> {{$toLocal(coin.lastPrice,2)}} </span>
                <span class="change d-flex justify-center align-items-center" v-if="coin.last24HourChange > 0" :class="coin.last24HourChange > 0 ? 'success ':'fail'">
                                {{$toLocal(coin.last24HourChange,2)}} %
                                <inline-svg :class="coin.last24HourChange > 0 ? 'green rotate':'red'" :src="require('../../../assets/Icons/arrow.svg')" />
                </span>
                <span class="change d-flex justify-center align-items-center" v-if="coin.last24HourChange < 0" :class="coin.last24HourChange > 0 ? 'success rotate':'fail'">
                                {{$toLocal(Math.abs(coin.last24HourChange),2)}} %
                                <inline-svg :class="coin.last24HourChange > 0 ? 'green rotate':'red'" :src="require('../../../assets/Icons/arrow.svg')" />
                </span>    
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'DashMarket',
        data() {
            return {
                coinsToman:[],
                coinsTether:[],
            }
        },
        computed:{
            coins(){
                let allCoins
                if(this.$route.params.tradeTo=='TETHER'){
                    allCoins=this.coinsTether
                }else{
                    allCoins=this.coinsToman
                }
                return allCoins
            }
        },
        methods:{
            async getCoins() {
                    this.state.loading = true
                    const res = await this.$axios.get('/trades/market-info',{
                        params: {
                            weeklyDataIncluded: true,
                        }
                    })
                    this.coinsToman = res.content.filter(a => a.marketType.includes('TOMAN') && a.relatedCoin !=='TOMAN')
                    this.coinsTether = res.content.filter(a => a.marketType.includes('TETHER') && a.relatedCoin !=='TOMAN')
            },
        },
        mounted(){
            this.getCoins();
        }
    }
</script>

<style lang="scss" scoped>
.trade-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: sticky;
        top: 0;
background: #F8F8F8;
/* p2p cards */
 
    span{
        font-weight: 400;
        font-size: clamp(10px,1vw,12px);
        color: var(--a-hover);
        width: 80px;
    }
}





.change{
    font-weight: 500;
    font-size: clamp(10px,1vw,14px);
}
.title{
            padding-top: 12px;
            display: flex;
            flex-direction: row;
            column-gap: 4px;
            align-items: center;
            img{
                width: 27px;
                height: 27px;
            }
            .text{
                display: flex;
                flex-direction: column;
                // row-gap: 5px;
                .faName{
                    color: var(--black);
                    font-weight: 500;
                    font-size: clamp(10px,1vw,12px);
                }
                .Unit{
                    color: rgba(132, 110, 110, 1);
                    font-weight: 500;
                    font-size: clamp(10px,1vw,12px);
                }
            }
        }
.rows{
    display: flex;
    flex-direction: column;
    //row-gap: 24px;
    height: 100%;

    overflow: scroll;
}
.row{
    cursor: pointer;
padding: 0 10px !important;
    max-height: 56px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    span{
        width: 80px;
    }
    .price{
        color: var(--black);
        font-weight: 500;
        font-size: clamp(10px,1vw,14px);
    }
}
.market-container{
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    padding:10px 15px;
background: #F8F8F8;
/* p2p cards */

box-shadow: 0px 0px 2px rgba(140, 150, 153, 0.34);
border-radius: 8px;
    border-radius: 4px;
    width: 30%;
    max-height: 410px;
    h3{
        position: sticky;
        top: 0;
        background: #F0F0F5;
        color: var(--black);
        font-weight: 700;
        font-size: clamp(14px,2vw,16px);
    }
}
@media only screen and(max-width:400px){
    .text{
        display: none !important;
    }
}
</style>