<template>
  <div class="p2p d-flex flex-col">

<div class="header d-flex flex-wrap" >
  <div class="d-flex secondary">
    <h3 class=" bolder d-flex align-self-center">معاملات حرفه ای</h3>
  </div>
  <div class="d-flex gap-xss align-items-center coin-name" >
    <img :src="'/coins/'+ $coinUnit[$route.params.tradeFrom] + '.png' " alt="" width="20" height="20">
    <span class="regular d-flex align-items-center gap-xss">
      {{$coinLabel[$route.params.tradeFrom] }}
      /  <span class="gray xxs"> {{ $coinLabel[$route.params.tradeTo] }}</span>
    </span>

  </div>

  <div class="d-flex price-container justify-space-between flex-wrap"  :key="''+ $route.params.tradeTo + $route.params.tradeFrom">

    <div class="cont d-flex flex-col green">
      <span class="small">قیمت لحظه ای</span>
      <span>{{$toLocal(data.lastPrice,$decimal[$route.params.tradeTo])}} </span>
    </div>
    <div class="cont d-flex flex-col green">
      <span class="small gray">بیشترین قیمت</span>
      <span>{{$toLocal(data.maxPrice,$decimal[$route.params.tradeTo])}}</span>
    </div>
    <div class="cont d-flex flex-col red">
      <span class="small gray">کمترین قیمت</span>
      <span>{{$toLocal(data.minPrice,$decimal[$route.params.tradeTo])}} </span>
    </div>
    <div class="cont d-flex flex-col">
      <span class="small gray">تغییر 24h</span>
       <span v-if="data.last24HourChange < 0" class="red"> {{$toLocal(Math.abs(data.last24HourChange),2)}}%- </span>    
                    <span v-if="data.last24HourChange > 0" class="green"> {{$toLocal(Math.abs(data.last24HourChange),2)}}%+ </span>
    </div>
    <div class="cont d-flex flex-col">
      <span class="small gray">حجم بازار</span>
      <span>{{$toLocal(data.last24HourVolume,$decimal[$route.params.tradeTo])}}</span>
    </div>


  </div>

  <div class="buttons"  >
    <button @click="$router.push('/Dashboard/trade/TOMAN/'+$route.params.tradeFrom)" :class="{'active-btn':$route.params.tradeTo=='TOMAN'}"          >تومان</button>
    <button @click="$router.push('/Dashboard/trade/TETHER/'+$route.params.tradeFrom)" :class="{'active-btn':$route.params.tradeTo=='TETHER'}">تتر</button>
  </div>

  <div class="notification mobile">
<img class="notif" src="../../assets/panel/notification-icon.svg" alt="">
<span class="dot"></span>
  </div>

</div>



   <div class="p2p-container">
     <div class="trade-content">
       <div class="coin-trade">
         <DashMarket />
         <LastTrades />
       </div>
       <div class="view">
         <Tradingview :key="$route.params.tradeFrom+$route.params.tradeTo+'trade'" />
         <Shop />
       </div>
       <Defth :key="$route.params.tradeFrom+$route.params.tradeTo+'defth'" />
     </div>


     <div class="tables">
       <History/>
     </div>

   </div>










  </div>
</template>
<script>

import DashMarket from '../Panel/Dashboard/DashMarket.vue'
import LastTrades from './LastTrades.vue'
import Tradingview from './Tradingview.vue'
import History from '../Panel/History/Main.vue'
import Shop from './Shop.vue'
import Defth from './Defth.vue'


export default {
  name: "P2P",
  components: { DashMarket, LastTrades, Tradingview, Shop, Defth, History,  },

  data(){
    return{
      data:[],
      dropDown:false,
      route:'P2P',
      selectedRoute:'',
      lastPrice :12039458,
      higestPrice :12039458,
      lowestPrice :12039458,
      changes :0,
      volume :13049458,
      routesName:[
        {
          name:'معامله ی حرفه ای',
          route:'P2P'
        },
        {
          name: 'معامله سریع(OTC)',
          route:'OTC'
        }
    ]
    }
  },

watch:{

'$route.params':function(){
  this.getTradeHeader()
}


},

  methods: {
    selectItem(index) {
      this.route = this.routesName[index].route
      // if(this.routesName[index].route !=='P2P'){
      //
      //   this.$router.push('/Dashboard/trade/otc')
      // }


      this.selectedRoute = this.routesName[index].name
      this.dropDown = !this.dropDown
    },


                async getTradeHeader() {
                const [res,] = await this.$http.get('/trades/market-info', {
                    params: {
                        weeklyDataIncluded: false,
                        market_type: this.$route.params.tradeFrom + '_' + this.$route.params.tradeTo
                    }
                })
                this.loading = false
                if(res){
                    this.data = res.content[0] || this.data
                    this.state.shopInfo.lastPrice = this.data.lastPrice
                    this.state.shopInfo.change24 = this.data.last24HourChange
                    // document.title = `بازار ${this.$coinLabel[this.tradeFrom]} - ${this.$coinLabel[this.tradeTo]}` + ' ｜ ' + this.$toLocal(this.data?.lastPrice,this.precision)
                }
            },
  },
  mounted() {
    this.selectedRoute = this.routesName[0].name;
    this.getTradeHeader()


  }
}
</script>
<style lang="scss" scoped>

.notification{

  width: 48px;
  height: 48px;
  background: #F8F8F8;
  justify-content: center;
  position: relative;
  display: flex;
  align-items: center;
/* p2p cards */
.dot{
      transition: 500ms ease-in-out;
  width: 6px;
  height: 6px;
  background:#DA2C38;
  border-radius: 50%;
  position: absolute;
top: 12px;
left: 16px;
}
.notif{
        transition: 500ms ease-in-out;
}

box-shadow: 0px 0px 2px rgba(140, 150, 153, 0.34);
border-radius: 8px;


&:hover{
.notif{

  transform: rotate(-35deg);
}
.dot{
  transform: translate(-4px, 5px);
}
}

}







.otc{
  justify-content: space-between !important;
}

.p2p{
  gap: 1rem;
}
.routes{
  position: relative;
}
.price-container{

}
.dropdown{
  position: absolute;
  height: 100px;
  top: 25px;
  width: 100%;
  max-width: 150px;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  background: var(--darker-white);
  padding: .5em;
  border-radius: .5em;
  z-index: 3;
}

.cont{
}
.header{
  gap: 1em;
}
.price-container{
  flex: 2;
  max-width: 1090px;
  padding: 0 3%;
}

.buttons{
  justify-content: center;
  padding: 6px 10px;
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 392px;
  align-items: center;
  box-shadow: 0px 0px 2px rgba(140, 150, 153, 0.34);
  border-radius: 8px;
  background: var(--darker-white);
  button{
    flex: 1;
    width: 100%;
    height: 36px;
  }
}



@media (max-width:500px){
  .mobile{
    display: none !important;
  }
}






@media(max-width: 800px){
  .header{
    flex-direction: column;
  }
  .coin-name{
    align-self: flex-start;
  }
  .price-container{
    flex-direction: column;
    gap: 1em;
  }

  img{
    align-self: flex-start;
  }
}














.p2p-container{
  display: flex;
  flex-direction: column;
  gap: 16px;
  //padding: 90px 16px;
  .trade-content{
    display: flex;
    flex-direction: row;
    gap: 16px;
    .view{
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 49%;
    }
    .coin-trade{
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 25%;
      .market-container{
        width: 100% !important;
      }
    }
  }
}
@media only screen and(max-width:1200px){

  .trade-content{
    flex-direction: column !important;
    align-items: flex-end !important;
    .view{
      width: 100% !important;
    }
    .coin-trade{
      flex-direction: row !important;
      width: 100% !important;
      flex-wrap: wrap;
    }
    .table-container{
      width: 100%;
    }
  }
}
@media only screen and(max-width:960px){
  .p2p{
//s
    padding: 10px 0 !important;
  }
  .header,.price-container{
    flex-direction: column;
    gap: 1em;
  }
}


@media screen and(max-width:500px) {
  input
  .mobile{
    display: none;
  }
  
}

</style>