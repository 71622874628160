<template>
    <div class="market-container">
        <span class="regular gray"> معاملات بازار </span>
        <div class="rows">
            <div v-if="$route.name=='AdvanceTrade'" class="trade-row">
                <span> قیمت </span>
                <span> مقدار  </span>
                <span> زمان </span>
            </div>
            <div v-for="(trade,index) in changeTrades" :key="index" class="row hover pointer">
                <span class="small" :class="trade.orderType.includes('BUY') ? 'buy' : 'red'"> {{$toLocal(trade.unitPrice,$decimal[$route.params.tradeTo])}} </span>
                <span class="small"> {{$toLocal(trade.amount,$decimal[$route.params.tradeFrom])}} </span>
                <span class="small"> {{$G2J(trade.createdAtDateTimed,'time','en')}} </span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'lastTrades',
        data() {
            return {
                LastTradesToman:[],
                LastTradesTether:[],
            }
        },
        computed:{
            changeTrades(){
                let allTrades
                if(this.$route.params.tradeTo=='TETHER'){
                    allTrades=this.LastTradesTether
                    
                }else{
                    allTrades=this.LastTradesToman
                }
                return allTrades
            }
        },
        methods:{
            async getLastTradesToman() {
                const res = await this.$axios.get('/trades/last-trades', {
                    params: {
                        marketType: this.$route.params.tradeFrom + '_' + 'TOMAN',
                        size: 20,
                        type: 'P2P'
                    }
                })
                this.LastTradesToman = res.content
                this.loading = false
            },
            async getLastTradesTether() {
                const res = await this.$axios.get('/trades/last-trades', {
                    params: {
                        marketType: this.$route.params.tradeFrom + '_' + 'TETHER',
                        size: 20,
                        type: 'P2P'
                    }
                })
                this.LastTradesTether = res.content
                this.loading = false
            },
        },
        mounted(){
            this.getLastTradesToman();
            this.getLastTradesTether();
        }
    }
</script>

<style lang="scss" scoped>
.buy{
    color: var(--green) !important;
}
.trade-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: sticky;
        top: 0;
        background: #F8F8F8;
    span{
        font-weight: 400;
        font-size: clamp(10px,1vw,12px);
        color: var(--a-hover);
        // width: 80px;
    }
}





.change{
    font-weight: 500;
    font-size: clamp(10px,1vw,14px);
}
.title{
            padding-top: 12px;
            display: flex;
            flex-direction: row;
            column-gap: 4px;
            align-items: center;
        }
.rows{
    display: flex;
    flex-direction: column;
    //row-gap: 24px;
    height: 100%;
    overflow: scroll;
}
.row{
  padding: 0 10px;
    //max-height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  min-height: 32px !important;
    // span{
    //     width: 80px;
    // }
    .price{
        color: var(--black);
        font-weight: 500;
        font-size: clamp(10px,1vw,14px);
    }
}
.market-container{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding:10px 15px;
    background: #F8F8F8;
    box-shadow: 0px 0px 8px -1px rgba(65, 70, 192, 0.2);
    border-radius: 4px;
    width: 30%;
    max-height: 410px;
    h3{
        position: sticky;
        top: 0;
        background: #F0F0F5;
        color: var(--black);
        font-weight: 700;
        font-size: clamp(14px,2vw,16px);
    }
}
@media only screen and(max-width:400px){
    .text{
        display: none !important;
    }
}
</style>