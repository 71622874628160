<template>
<div class="modal-backdrop" @click="$emit('close')">
  <div class="modal d-flex flex-col">
    <div class="d-flex justify-space-between">
      <span >
      سفارش
      <span  :class="{'green':type==='خرید', 'red':type==='فروش'} "> {{type}}</span>


      </span>
      <img src="../../assets/Icons/close.svg" alt="" @click="$emit('close')">
    </div>

    <span class="small">آیا از سفارش خود مطمئن هستید؟</span>

    <div class="d-flex justify-space-between regular">
      <span>مقدار</span>
      <span>{{amount}}</span>
    </div>
    <div class="d-flex justify-space-between regular">
      <span>کارمزد</span>
      <span>{{fee}}</span>
    </div>
    <div class="d-flex justify-space-between regular">
      <span>دریافتی</span>
      <span>{{totalPrice}}</span>
    </div>
    <button class="w100 btn-primary" @click.prevent="placeOrder"> تایید نهایی</button>
  </div>
</div>
</template>

<script>
export default {
  name: "TransactionModal",
  props:['type','amount' , 'fee', 'totalPrice','orderType' ,'unitPrice', 'stopPrice','date'],
  methods: {
    async placeOrder() {
      //order type
      let marketType = this.$route.params.tradeFrom + '_' + this.$route.params.tradeTo;
      let type;
      let orderDetail;
      if (this.orderType === 'market') {
        type = 'MARKET_BUY';
        console.log('market',type)
      }  if (this.orderType === 'limit') {
        console.log('limit',type)
        type = 'LIMITED_BUY';
      }
       if (this.orderType ==='stop-limit'){
        type = 'STOP_LIMIT_BUY';
        console.log('stop-limit', type)
      }

      orderDetail = {
        marketType: marketType,
        amount: this.$S2N(this.amount),
        orderType: type,
        expiresAt: this.date || null,
        unitPrice: this.$S2N(this.unitPrice),
        stopPrice: this.$S2N(this.stopPrice) || null,
        wholePrice: this.$S2N(this.totalPrice),
      }
console.log(orderDetail)
      try {
        this.state.loading = true;
        await this.$axios.post(`/orders/buy`, orderDetail, {
          params: {
            force: true
          }
        })
        this.loading = false
        this.$emit('close')
        this.state.loading = false;
        this.$error('', 'سفارش با موفقیت ثبت شد', 'success')
      } catch (error) {
        this.loading = false
        this.$emit('close')
        if (error.response.data.message) {
          //  === "the amount of order is bigger than market liquidity"
          // await this.$error('', 'حجم درخواستی بیش از مقدار بازار است.')
          this.$error('', error.response.data.message)
        }
      }
      this.state.loading = false;








    }
  }
}
</script>

<style lang="scss" scoped>
.modal{

  background: var(--darker-white);
  gap: 1.5em;
  min-width: 300px;
  //min-height: 300px;
  padding: 1.5em 1.7em;
border-radius: .7rem;


}
button{
  height: 40px;
}
</style>