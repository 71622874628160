<template>
  <div class="container" :class="{'tables':$route.path.includes('trade')}">
    <table>
      <tr class="head">
        <td> زمان</td>
        <td class="tablet"> آی پی</td>
        <td class="tablet"> نوع دستگاه</td>
        <td class="phone"> پلتفرم</td>
        <td class="phone"> نوع مرورگر</td>
        <td>وضعیت</td>
      </tr>
            <perfect-scrollbar @ps-y-reach-end="check" ref="scroll">
<tbody>
        <tr v-for="(item,index) in tableContents" :key="index" class="body hover">
          <td class="text"> {{ $G2J(item.loginRequestedDateTime, 'faDateTime') }}</td>
          <td class="text tablet">{{ item.ipAddress }}</td>
          <td class="text tablet">{{ item.deviceType }}</td>
          <td class="text phone">{{ item.osType }}</td>
          <td class="text">{{ item.browser }}</td>
          <td class="phone" >
{{item.isSuccessful ? "موفق":"ناموفق"}}

          </td>
      </tr>
</tbody>
</perfect-scrollbar>
    </table>
  </div>
</template>

<script>
export default {
  name: 'OrderHistory',
  data() {
    return {

      tableContents: [

      ],
      lastPage:1,
      page:0
    }
  },
    methods: {
    async check() {
      if (this.page <= this.lastPage && !this.loading) { //for scroll loading
        this.loading = true
        this.page++ //for scroll loading
        const [res,] = await this.$http.get('/users/login-history', {
          params: {
            size: 10,
            page: this.page
          }
        })
        if (res) {
          this.lastPage = res.totalPages
          this.tableContents = [...this.tableContents, ...res.content] //for scroll loading
          // this.tableContents = res.content //for pagination
        }
        this.loading = false
      }
    },
  },
  mounted() {
    this.check()

  }
}
</script>

<style lang="scss" scoped>
.link {
  color: var(--primary);
  text-decoration-line: underline;
  cursor: pointer;
} 

tbody,tr{
display: table;  
width: 100%;
}
.ps{
  max-height: 400px;
}
.container {
  display: flex;
  width: 100%;
  max-width: none !important;
  background: #FAF9F7;
  border-radius: 8px;
  padding: 10px !important;
    max-height: 564px;
}

.fail-btn {
  background: var(--red);
  color: var(--white);
  font-weight: 500;
  font-size: clamp(8px, 1vw, 12px);
}

table {
  td{
    height: 56px !important;
  }
  width: 100%;

  .head {
    td {
      color: #A1A1AC;
    }
  }
.body{
  td{
   border-bottom: 1px solid var(--gray-lighter);
  }
}
  td {
    width: 10%;
    text-align: center;
    height: 55px;
    font-weight: 500;
    font-size: clamp(8px, 1vw, 12px);

  }
}

@media only screen and(max-width: 900px) {
  .remove {
    display: none;
  }
}

@media only screen and(max-width: 600px) {
  .remove2 {
    display: none;
  }
}
.tables{
  max-height: 300px;
  min-height: 300px;
  overflow: auto;
}

@media screen and (max-width: 768px) {
  .tablet {
    display: none;
  }
  @media screen and (max-width: 450px) {
    .phone {
      display: none;
    }
  }
}



</style>