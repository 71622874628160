<template>
  <div id="app">
    <Navbar/>
    <transition enter-active-class="animate__animated  animate__fadeIn" >
      <router-view/>
    </transition>

    <Footer/>
    <Loading v-if="state.loading"/>
  </div>
</template>

<script>

import Navbar from "@/components/PublicComponents/Navbar";
import Footer from "@/components/PublicComponents/Footer";
import Loading from "@/Loading";

export default {
  metaInfo(){
    return{
      meta: [
        { charset: 'utf=8' },
        {lang:'persian-fa'},

      ]
    }
  },
  name: 'App',
  components: {Loading, Footer, Navbar},
}

</script>

<style lang="scss" scoped>
#app {

  background: #F6F5F4;
  //font-family: 'VistaDev';



}
</style>
