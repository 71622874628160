import Forms from "../components/Auth/Main.vue";
import Trade from '../components/P2P/Main.vue';

export default [
    {
        path: "/",
        name: "HomePage",
        component: () => import("../components/HomePage/Main.vue"),
    },
    {
        path: "/aboutus",
        name: "AboutUs",
        component: () => import("../components/PublicPages/AboutUs.vue"),
    },
    {
        path: "/faq",
        name: "FAQ",
        component: () => import("../components/PublicPages/FAQ.vue"),
    },
    {
        path: "/privacypolicy",
        name: "PrivacyPolicy",
        component: () => import("../components/PublicPages/PrivacyPolicy.vue"),
    },
    // {
    //     path :'/contactus',
    //     name :'ContactUs',
    //     component: () => import('../components/PublicPages/ContactUs.vue')
    // },
    {
        path: "/fees",
        name: "Fees",
        component: () => import("../components/PublicPages/Fee.vue"),
    },
    {
        path: "/market",
        name: "Market",
        component: () => import("../components/PublicPages/Market.vue"),
    },
    {
        path: "/auth/register",
        name: "Register",
        component: Forms,
    },
    {
        path: "/regotp",
        name: "RegisterOTP",
        component: Forms,
    },
    {
        path: "/guide",
        name: "Guide",
        component: () => import("../components/PublicPages/Guide.vue"),
    },
    {
        path: "/notifications",
        name: "Notifications",
        component: () => import("../components/PublicPages/notifications.vue"),
    },

    {
        path: "/auth/otplogin",
        name: "OtpLogin",
        component: Forms,
    },
    {
        path: "/auth/login",
        name: "Login",
        component: Forms,
    },
    {
        path: "/auth/forgetpass",
        name: "ForgetPass",
        component: Forms,
    },
    {
        path: "/auth/forgetotp",
        name: "OtpForget",
        component: Forms,
    },
    {
        path: "/auth/registermobile",
        name: "RegisterMobile",
        component: Forms,
    },
    {
        path: "/auth/newpass",
        name: "NewPass",
        component: Forms,
    },

    // {
    //     path: "/exchange",
    //     name: "Exchange",
    //     component: () => import("../components/OTC/Main.vue"),
    // },

    {
        path: '/kyc',
        name: 'KYC',
        component: () => import('../components/Panel/KYC/Main.vue'),
    },
    {
        path: "/Dashboard",
        component: () => import("../components/Panel/Main.vue"),
        children: [
            {
                path: "",
                name: "Dashboard",
                component: () => import("../components/Panel/Dashboard/Main.vue"),
            },

            {
                name:'OTC',
                path:'OTC',
                component:() => import('../components/OTC/Main.vue'),
            },




            // {
            //     path : 'finotech',
            //     name:'FinoTech',
            //     component:() => import('../components/Panel/KYC'),
            // },
            {
                path: "Wallet",
                name: "Wallet",
                component: () => import("../components/Panel/WalletP2P/Main.vue"),
            },
            {
                path: "ExchangeWallet",
                name: "ExchangeWallet",
                component: () => import("../components/Panel/WalletExchange/Main.vue"),
            },
            // {
            //     path :'Activeorders',
            //     name :'Activeorders',
            //     component: () => import('../components/Panel/ActiveOrdersa/Main.vue'),
            // },
            {
                path: "Account",
                name: "Account",
                component: () => import("../components/Panel/KYC/Main.vue"),
            },
            // {
            //     path :'validation',
            //     name :'Validation',
            //     component: Account,
            // },
            {
                path: "History",
                name: "History",
                component: () => import("../components/Panel/History/Main.vue"),
                // children:[
                //     {
                //         path: "order-history",
                //         component: () => import("../components/Panel/History/OrderHistory.vue"),
                //     },
                //     {
                //         path: 'trade-history',
                //         component: () => import("../components/Panel/History/TradeHistory.vue"),
                //     },
                //     {
                //         path: 'login-history',
                //         component: () => import("../components/Panel/History/LoginHistory.vue"),
                //     },
                //     {
                //         path:'crypto-transactions',
                //         component: () => import("../components/Panel/History/CryptoTrans.vue"),
                //     },
                //     {
                //         path: 'rial-transactions',
                //         component: () => import("../components/Panel/History/RialTrans.vue"),
                //     }
                // ],
                // redirect: '/Dashboard/History/order-history'
            },
//             {
// path:'/Dashboard/History',
//                 redirect: '/Dashboard/History/order-history'
//             },




            {
                path: "Support",
                name: "Support",
                component:()=> import("../components/Panel/Tickets/Main.vue"),
            },
            {
                path: "Tickets",
                name: "Tickets",
                component: () => import("../components/Panel/Tickets/Main.vue"),
            },
            {
                path: "Notifications",
                name: "Notifications",
                component: () => import("../components/Panel/Notifications/Main.vue"),
            },
            {
                path: "Setting",
                name: "Setting",
                component: () => import("../components/Panel/Settings/Main.vue"),
            },
            {
                path: "Invitefriends",
                name: "Invitefriends",
                component: () => import("../components/Panel/InviteFriends/Main.vue"),

            },
            {
                path: "trade",
                redirect: "trade/TOMAN/BITCOIN",
                name: "AdvanceTrade",
                component: Trade,
            },
            {
                path: "trade/TETHER/TETHER",
                redirect: "trade/TOMAN/TETHER",
            },
            {
                path: "trade/TOMAN/TOMAN",
                redirect: "trade/TOMAN/TETHER",
            },
            {
                path: "trade/:tradeTo/:tradeFrom",
                component: Trade,
                name: "AdvanceTrade",
                props: true,
            },




            {
                path: "*",
                redirect: "/",
            },
        ],
    },
];
