<template>
  <form class="register-container">
    <h2>ثبت نام</h2>
    <div class="inputs-container">
      <div class="label-container">
        <div
          :class="emailWarn ? 'div-error' : femail ? 'div-active' : ''"
          class="input"
        >
          <label >
            <span
              :class="
                emailWarn
                  ? 'span-error'
                  : femail || data.email
                  ? 'span-active'
                  : ''
              "
            >
              آدرس ایمیل
            </span>
            <input @focus="femail = true" v-model="data.email" type="text" />
          </label>
          <inline-svg
            :class="emailWarn ? 'svg-error' : femail ? 'svg-active' : ''"
            class="svg"
            :src="require('../../assets/Icons/Email.svg')"
          />
        </div>
        <span v-if="emailWarn" class="warn"> {{ emailWarn }} </span>
      </div>

      <div class="label-container">
        <div
          :class="passWarn ? 'div-error' : fpass ? 'div-active' : ''"
          class="input"
        >
          <label >
            <span
              :class="
                passWarn
                  ? 'span-error'
                  : fpass || data.password
                  ? 'span-active'
                  : ''
              "
            >
              رمز عبور
            </span>
            <input @focus="fpass = true" v-model="data.password" :type="type" />
          </label>
          <inline-svg
            @click.prevent="type = type === 'password' ? 'text' : 'password'"
            :class="passWarn ? 'svg-error' : fpass ? 'svg-active' : ''"
            class="svg"
            :src="require('../../assets/Icons/password.svg')"
          />
        </div>
        <span v-if="passWarn" class="warn"> {{ passWarn }} </span>
      </div>

      <div class="label-container">
        <div class="input">
          <label >
            <span
              :class="{
                'span-active': data.refferalCode || fref,
              }"
            >
              (اختیاری)کد دعوت
            </span>
            <input
              @focus="fref = true"
              v-model="data.refferalCode"
              type="text"
            />
          </label>
        </div>
        <span v-if="passWarn" class="warn"> {{ passWarn }} </span>
      </div>

      <div class="terms-container">
        <input type="checkbox" @change="check = !check" />
        <span ><a href="" class="secondary" @click.prevent="$router.push({name:'PrivacyPolicy'})"> قوانین سایت</a></span>
        <span> را خوانده و پذیرفته ام .</span>
      </div>

<!--      <button-->
<!--        @click.prevent="registerUser"-->
<!--        class="btn-primary"-->
<!--        :disabled="!data.email || !data.password || !check"-->
<!--      >-->
<!--        ثبت نام-->
<!--      </button>-->



      <button
          @click.prevent="registerUser"
          class="btn-primary"
          :disabled="!data.email || !data.password || !check"
      >
        ثبت نام
      </button>






      <span class="or">یا</span>

      <button class="btn-secondary-outline">
        <img src="../../assets/Icons/google.svg" alt="" />
        ثبت نام از طریق گوگل
      </button>

<span class="or regular">
قبلا ثبت نام کرده اید؟

<a href="" class="secondary" @click.prevent="$router.push('/auth/login')">وارد شوید </a>
</span>


    </div>
  </form>
</template>

<script>
export default {
  name: "Register",
  data() {
    return {
      femail: false,
      fpass: false,
      fref: false,
      check: false,
      type: "password",
      data: {
        email: "",
        password: "",
        referralCode: "",
        osType: "",
        deviceType: "",
        browser: "",
      },
    };
  },
  computed: {
    emailWarn() {
      let err;
      if (this.femail) {
        if (!this.data.email) {
          err = "ایمیل را وارد کنید";
        } else if (!this.$validEmail(this.data.email)) {
          err = "ایمیل صحیح را وارد کنید";
        } else {
          err = false;
        }
      } else {
        err = false;
      }
      return err;
    },

    passWarn() {
      let err;
      if (this.fpass) {
        if (!this.data.password) {
          err = "رمز عبور را وارد کنید";
        } else if (this.data.password.length < 6) {
          err = "رمز عبور باید حداقل 6 کاراکتر باشد";
        } else if (!this.$validPass(this.data.password)) {
          err = "رمز عبور صحیح را وارد کنید";
        } else {
          err = false;
        }
      } else {
        err = false;
      }
      return err;
    },
  },
  methods: {
    showingPass() {
      if (this.type === "password") {
        this.type = "text";
      } else if (this.type === "text") {
        this.type = "password";
      }
    },

    //place verifications here
    async checkEmail() {
      if (!this.$validEmail(this.data.email) || !this.data.email) {
        this.emailError = "ایمیل صحیح وارد نمایید";
        this.$error("اطلاعات وارد شده اشتباه است", "ایمیل صحیح وارد نمایید");
        return Promise.reject("repeat failed : " + this.data.email);
      } else {
        this.emailError = "";
      }
    },
    async checkPassword() {
      if (
        !this.$validPass(this.data.password) ||
        this.data.password.length < 8
      ) {
        this.passError =
          "رمز باید حداقل 8 کاراکتر باشد و شامل حرف بزرگ ، حرف کوچک و عدد باشد";
        this.$error(
          "اطلاعات وارد شده اشتباه است",
          "رمز باید حداقل 8 کاراکتر باشد و شامل حرف بزرگ ، حرف کوچک و عدد باشد"
        );
        return Promise.reject("wrong pass : " + this.data.password);
      } else {
        this.passError = "";
      }
    },
    //axios functions placed in here
    googleSso() {
      window.location.href = this.$axios.defaults.baseURL + "/users/google-sso";
    },
    verifyMethod() {
      this.recaptcha = true;
    },
    async registerUser() {
      await this.checkEmail();
      await this.checkPassword();
      // this.state.loading=true;
      const res = await this.$axios.post("users/register", this.data);
      console.log(res);
      if (
        res.message === "user created successfully , verification email sent"
      ) {
        if (res.baseDTO.towStepAuthenticationType === "EMAIL") {
          this.$cookies.set("username", this.data.email);
          this.state.userInfo.email = this.data.email;
          await this.$router.push({name:'RegisterOTP'});
        }
      } else if (res.token) {
        this.$setToken(res.token, this.state.userInfo.remember);
        await this.$router.push("/");
      }
      console.log("success");
    },
  },

  mounted() {
    //mount platform and etc.
    this.data.osType = this.$detectOs();
    this.data.browser = this.$detectBrowser() || "CHROME";
    this.data.deviceType = this.$detectDevice();
    //save refferal in the localstorage
    if (this.$route.query.ref || localStorage.referralCode) {
      localStorage.referralCode =
        this.$route.query.ref || localStorage.referralCode;
      this.data.referralCode =
        this.$route.query.ref || localStorage.referralCode;
    }
  },

  created: function () {
    window.addEventListener("click", (event) => {
      if (!event.target.closest(".input")) {
        this.femail = false;
        this.fpass = false;
        this.emailWarn = "";
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.terms-container {
  display: flex;
  font-size: clamp(12px, 1.5vw, 14px);
  input[type="checkbox"] {
    margin-left: 10px;
  }
  align-items: center;
}
.or {
  align-self: center;
}
.btn-primary,
.btn-secondary-outline {
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.label-container {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  .warn {
    color: var(--red);
    font-weight: 300;
    font-size: clamp(10px, 1vw, 12px);
  }
  .svg-active {
    fill: var(--secondary);
  }
  .svg-error {
    fill: var(--red) !important;
  }
  .div-active {
    opacity: 1 !important;
    box-shadow: inset 0px -2px 0px var(--secondary);
  }
  .div-error {
    opacity: 1 !important;
    box-shadow: inset 0px -2px 0px var(--red);
  }
  .input {
    transition: 700ms;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 4px;
    align-items: center;
    padding: 14px 16px;
    border-radius: 8px 8px 0px 0px;
background: #F0F0F0;
    width: 100%;
    opacity: 0.7;
    max-height: 50px;
    &:hover {
      opacity: 1;
    }
    .svg {
      width: 20px;
      height: 16px;
    }
    label {
      width: 90%;
      display: flex;
      flex-direction: column;
      row-gap: 3px;
      position: relative;
      z-index: 1;
      input {
        transform: translateY(10px);
        background: transparent;
        z-index: 3;
        color: var(--black);
        font-weight: 400;
        font-size: clamp(14px, 2vw, 16px);
      }
      span {
        position: absolute;
        color: var(--a-hover);
        font-weight: 400;
        font-size: clamp(14px, 2vw, 16px);
        z-index: 2;
        transition: 700ms;
      }
      .span-active {
        transform: translate(15px, -11px) scale(0.7, 0.7);
        color: var(--secondary) !important;
      }
      .span-error {
        transform: translate(15px, -11px) scale(0.7, 0.7);
        color: var(--red) !important;
      }
    }
  }
}

.inputs-container {
  display: flex;
  flex-direction: column;
  row-gap: 23px;
  width: 100%;
}

.register-container {
  width: 100%;
  max-width: 460px;
  background: var(--homepage-bg);
  box-shadow: 0px 0px 8px -1px rgba(65, 70, 192, 0.2);
  border-radius: 12px;
  padding: 24px ;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  align-items: flex-start;
  h2 {
    align-self: center;
  }
}
</style>