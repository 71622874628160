<template>
  <div class="container" :class="{'tables':$route.path.includes('trade')}">
    <table>
      <tr class="head">
        <td> بازار</td>
        <td class="remove"> زمان و تاریخ</td>
        <td class="remove2"> سمت</td>
        <td class="remove"> نوع</td>
        <td> قیمت واحد</td>
        <td> مقدار</td>
        <td class="remove"> کارمزد</td>
        <td class="remove2"> مبلغ کل</td>
      </tr>
      
<perfect-scrollbar @ps-y-reach-end="page++;getTrades">
  <tbody>
  <tr v-for="(item,index) in coins" :key="index" class="body hover">
    <td class="rtl"> {{
        item.orderType.includes('BUY') ? ($coins[item.destinationCoin].persianName + '-' + $coins[item.sourceCoin].persianName) : ($coins[item.sourceCoin].persianName + '-' + $coins[item.destinationCoin].persianName)
      }}
    </td>
    <td> {{
        $G2J(item.createdAtDateTime)
      }}
    </td>
    <td class="remove" :class="item.orderType.includes('BUY') ? 'green':'red'"> {{
        item.orderType.includes('BUY') ? ' خرید ' : ' فروش '
      }}
    </td>
    <td class="remove" v-if="item.orderType.includes('LIMITED')"> حد</td>
    <td class="remove" v-if="item.orderType.includes('MARKET')"> بازار</td>
    <td class="remove" v-if="item.orderType.includes('EXCHANGE')"> صرافی</td>
    <td class="remove2"> {{
        $toLocal(item.unitPrice)
      }}
    </td>
    <td> {{
        $toLocal(item.amount, item.orderType.includes('BUY') ? $decimal[item.sourceCoin] : $decimal[item.destinationCoin])
      }}
    </td>
    <td class="remove"> {{
        item.wagePercent
      }}%
    </td>
    <td> {{
        $toLocal(item.wholePrice,item.orderType.includes('BUY')?$decimal[item.sourceCoin]:$decimal[item.sourceCoin])
      }}
    </td>
  </tr>
  </tbody>
</perfect-scrollbar>
    </table>
  </div>
</template>

<script>
export default {
  name: 'OrderHistory',
  props:['marketType'],
  data() {
    return {
      listTables: [
        {
          amount: 0.003,
          sourceCoin: 'BITCOIN',
          marketType: 'BITCOIN_TOMAN',
          orderType: 'MARKET_BUY',
          receivedAmount: 89.685,
          wholePrice: 12345678,
          createdAtDateTime: "2022-06-01T15:10:35",
          unitPrice: 30000,
          wagePercent: 0.35,
          situation: 'COMPLETED'
        },
        {
          amount: 0.003,
          sourceCoin: 'BITCOIN',
          marketType: 'BITCOIN_TOMAN',
          orderType: 'MARKET_BUY',
          receivedAmount: 89.685,
          wholePrice: 12345678,
          createdAtDateTime: "2022-06-01T15:10:35",
          unitPrice: 30000,
          wagePercent: 0.35,
          situation: 'CANCELED'
        },
        {
          amount: 0.003,
          sourceCoin: 'BITCOIN',
          marketType: 'BITCOIN_TOMAN',
          orderType: 'MARKET_BUY',
          receivedAmount: 89.685,
          wholePrice: 12345678,
          createdAtDateTime: "2022-06-01T15:10:35",
          unitPrice: 30000,
          wagePercent: 0.35,
          situation: 'EXPIRED'
        },
        {
          amount: 0.003,
          sourceCoin: 'BITCOIN',
          marketType: 'BITCOIN_TOMAN',
          orderType: 'MARKET_BUY',
          receivedAmount: 89.685,
          wholePrice: 12345678,
          createdAtDateTime: "2022-06-01T15:10:35",
          unitPrice: 30000,
          wagePercent: 0.35,
          situation: 'COMPLETED'
        },
        {
          amount: 0.003,
          sourceCoin: 'BITCOIN',
          marketType: 'BITCOIN_TOMAN',
          orderType: 'MARKET_BUY',
          receivedAmount: 89.685,
          wholePrice: 12345678,
          createdAtDateTime: "2022-06-01T15:10:35",
          unitPrice: 30000,
          wagePercent: 0.35,
          situation: 'CANCELED'
        },
        {
          amount: 0.003,
          sourceCoin: 'BITCOIN',
          marketType: 'BITCOIN_TOMAN',
          orderType: 'MARKET_BUY',
          receivedAmount: 89.685,
          wholePrice: 12345678,
          createdAtDateTime: "2022-06-01T15:10:35",
          unitPrice: 30000,
          wagePercent: 0.35,
          situation: 'EXPIRED'
        },
      ],
      coins: [],
      page:1,
      lastPage:1,
    }
  },
  methods: {
    async getTrades() {
      let query={
          orderType:this.marketType==='خرید'?'MARKET_BUY':'MARKET_SELL',

      }
      if(this.marketType==='خرید'){
        query.sourceCoin?query.delete('sourceCoin'):null;
        query.destinationCoin = this.state.searchCoin
      }
      if(this.marketType==='فروش'){
query.destinationCoin?query.delete('destinationCoin'):null;
        query.sourceCoin = this.state.searchCoin
      }
      if(this.page<=this.lastPage){
        const res = await this.$axios.get('https://tiirex.website/exchange/api/trades?type=P2P',{
          params:query
        })
        console.log(res.content);
        this.lastPage = res.totalPages;
        this.coins = res.content;
      }
    }
  },
  mounted() {
    this.getTrades()
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  width: 100%;
  max-width: none !important;
  background: #FAF9F7;
  border-radius: 8px;
  padding: 10px;
}
tbody,tr{
  display: table;
  width: 100%;

}
.ps{
  max-height: 400px;
}
.fail-btn {
  background: var(--red);
  color: var(--white);
  font-weight: 500;
  font-size: clamp(8px, 1vw, 12px);
}

table {
  td {
    height: 56px !important;
  }


  td:first-child {
    text-align: right;
  }

  td:last-child {
    text-align: left;
  }

  td {
    padding: 0 10px;
  }


  width: 100%;

  .head {
    position: sticky;
    top: 0;
    background: var(--darker-white);

    td {
      height: 48px;
      color: #A1A1AC;
    }
  }

  td {
    width: 10%;
    text-align: center;
    height: 35px;
    font-weight: 500;
    font-size: clamp(8px, 1vw, 12px);
  }
}

.body {
  td {
    height: 56px;
    border-bottom: 1px solid var(--gray-lighter);
  }
}

@media only screen and(max-width: 900px) {
  .remove {
    display: none;
  }
}

@media only screen and(max-width: 600px) {
  .remove2 {
    display: none;
  }
}

.tables {
  max-height: 300px;
  min-height: 300px;
  overflow: auto;
}

</style>