<template>
  <div class="register-container" @submit.prevent="submition">
    <form class="form">
      <div class="title d-flex flex-col">
        <h3 class="secondary">ثبت نام</h3>
      </div>
      <div class="email-container d-flex flex-col w100">
        <p>
          با وارد کردن شماره موبایل خود کد تایید را دریافت کنید و در کمتر از چند
          دقیقه ثبت نام کنید.
        </p>


      </div>

<div class="input-email">
  <input

      type="text"
      placeholder="شماره تلفن"
      v-model="mobile"
  />
  <inline-svg :src="require('../../assets/Icons/phone.svg')" ></inline-svg>
</div>

      <button class="btn-primary" id="btn">تایید</button>
    </form>
  </div>
</template>

<script>
// const goto = {
//   EMAIL: '/newpass',
//   // SMS: '/',
//   // GOOGLE_AUTHENTICATOR: '/',
// }

export default {
  name: "Forget",
  data() {
    return {
      mobile: "",
    };
  },
  methods: {
    async checkMobile() {
      //   if (!this.$validEmail(this.email) || !this.email) {
      //     this.emailError = 'ایمیل صحیح وارد نمایید'
      //     this.$error('مقادیر اشتباه', 'ایمیل وارد شده صحیح نیست', '', '', '', '2500')
      //     return Promise.reject('repeat failed : ' + this.email)
      //   } else return this.emailError = ''
    },

    async submition() {
      this.checkMobile();
      if (!this.emailError) {
        // const res = await this.$axios.post('/users/forget-password?email=' + this.email)
        // if (res.message === 'code sent successfully.') {
        //   this.state.userInfo.email = this.email;
        //   this.state.userInfo.mobilenumber = res.baseDTO.starTwoStepAuthentication || '';
        //   this.state.userInfo.towStepAuthenticationType = res.baseDTO.towStepAuthenticationType;
        // } else {
        //   this.state.userInfo.towStepAuthenticationType = 'EMAIL'
        // }
        // await this.$router.push(goto[res.baseDTO.towStepAuthenticationType])
        this.$router.push({ name: "Register" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.forget {
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: clamp(20px, 2vw, 24px);
    font-weight: 500;
  }

  p {
    align-self: flex-start;
  }
}

.input-email {
  //   color: white;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 36px;
  border: 0.5px solid var(--black);
  border-radius: 4px;
  background: transparent;
}

.title {
  width: 100%;
  gap: 10px;

  h3 {
    align-self: center;
  }

  p {
    align-self: flex-start;
  }
}

.email-container {
}

.register-container {
  width: 100%;
  max-width: 460px;
  background: var(white);
  box-shadow: 0px 0px 8px -1px rgba(65, 70, 192, 0.2);
  border-radius: 12px;
  padding: 24px;
  display: flex;
  flex-direction: column;

  row-gap: 50px;
  align-items: center;
  h2 {
    align-self: center;
  }
  .form {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    button {
      width: 90%;
      height: 2.2em;
    }
  }
}
</style>