<template>
  <div class="container" :class="{'tables':$route.path.includes('trade')}">
    <table>
      <tr class="head">
        <td> رمزارز </td>
        <td class="remove"> زمان و تاریخ </td>
        <td> مقدار </td>
        <td class="remove"> کارمزد </td>
        <td class="remove2"> شبکه </td>
        <td class="remove"> آدرس مقصد </td>
        <td > شناسه تراکنش </td>
        <td class="remove2"> وضعیت </td>
      </tr>



      <perfect-scrollbar
          ref="scroll" @ps-y-reach-end="page++;pageW++;getData">
        <tbody>
        <tr v-for="(item,index) in dataTable" :key="index" class="body hover">
          <td>{{$coins[item.relatedCoin].persianName}}</td>
          <td> {{
              $G2J(item.createdAtDateTime)
            }}
          </td>
          <td class="remove"> {{
              $toLocal(item.amount, 0)
            }}
          </td>
          <td class="remove">-</td>
          <td class="remove"> {{
              item.tokenType === null ? '-' : item.tokenType
            }}
          </td>
          <td class="remove2"> {{
              item.destinationWalletAddress? item.destinationWalletAddress : '-'
            }}
          </td>
          <td> {{
              item.hashCode === "" ? '_' : item.hashCode
            }}
          </td>
<template v-if="item.transactionStatus">
  <td v-if="transactionType='برداشت'" class="remove" :class="item.transactionStatus.includes('RAISED_BY_USER') ? 'red':'green'"> {{
      item.transactionStatus?(item.transactionStatus.includes('PENDING') ? ' موفق ' : ' ناموفق '):'-'
    }}
  </td>

</template>
          <template v-if="item.depositRequestStatus">
            <td v-if="transactionType!='برداشت'" class="remove" :class="item.depositRequestStatus.includes('RAISED_BY_USER') ? 'red':'green'"> {{
                item.depositRequestStatus?(item.depositRequestStatus.includes('PENDING') ? ' موفق ' : ' ناموفق '):'-'
              }}
            </td>
          </template>
        </tr>
        </tbody>
      </perfect-scrollbar>
    </table>
  </div>
</template>

<script>
export default {
  name:'OrderHistory',
  props: ['transactionType'],
  data() {
    return {
      tableContents:[],
      deposits:[],
      withdraws:[],
      lastPage:1,
      page:1,
      lastPageW:1,
      pageW:1,
    }
  },

  computed: {
    dataTable() {
      let data;
      if (this.transactionType == 'واریز') {
        data = this.deposits
      } else {
        data = this.withdraws;
      }
      console.log(data);
      return data;
    },
    //  getData(){
    //   let data;
    //   if(this.transactionType=='واریز'){
    //     data=this.getDeposits()
    //   }
    //   else{
    //     data=this.getWithdraws();
    //   }
    //   console.log(data);
    //   return data;
    //
    // }

  },
  methods:{
    async getDeposits(){
      console.log(this.page);
      if(this.page<=this.lastPage){
        // this.page++
        const res =await this.$axios('/wallets/deposit',{
          params:{
            // relatedCoin:'TOMAN',
            size:10,
            page:this.page,
            relatedCoin:this.state.searchCoin
          }
        })

        if(res){

          this.lastPage = res.totalPages;
          this.deposits=[...this.deposits,...res.content]

        }
      }

    },
    async getWithdraws(){
console.log(this.pageW)
      if(this.pageW<=this.lastPageW){
        // this.pageW++
        const res = await this.$axios('/wallets/withdrawal-requests',{
          params:{
            // relatedCoin:'TOMAN',
            size:10,
            page:this.pageW,
            relatedCoin:this.state.searchCoin
          }
        })
        console.log(res);
        if(res){
          this.lastPageW = res.totalPages;
          this.withdraws=[...this.withdraws,...res.content]

        }
      }

    },
    getData(){

      this.getDeposits();
      this.getWithdraws();
      this.mergingTables();
    },
    mergingTables(){
      this.tableContents = [...this.deposits,...this.withdraws]
    }
  },
  async mounted(){
    await this.getDeposits()
    await this.getWithdraws()
    console.log(this.tableContents)
  }

}
</script>

<style lang="scss" scoped>
tbody, tr {
  width: 100%;
  display: table;
}

.ps {
  max-height: 400px;
}

.container{
  display: flex;
  width: 100%;
  max-width: none !important;
  background: #FAF9F7;
  border-radius: 8px;
  padding: 10px;
}
.fail-btn{
  background: var(--red);
  color: var(--white);
  font-weight: 500;
  font-size: clamp(8px,1vw,12px);
}
table{
  td{
    height: 56px !important;
  }
  width: 100%;
  .head{
    td{
      height: 48px;
      color:#A1A1AC;
    }
  }
  td{
    width: 10%;
    text-align: center;
    height: 35px;
    font-weight: 500;
    font-size: clamp(8px,1vw,12px);
  }
}

.body{
  td{
    height: 56px;
    border-bottom: 1px solid var(--gray-lighter);
  }
}
@media only screen and(max-width:900px){
  .remove{
    display: none;
  }
}
@media only screen and(max-width:600px){
  .remove2{
    display: none;
  }
}

.tables{
  max-height: 300px;
  min-height: 300px;
  overflow: auto;
}

</style>