<template>
  <form class="buysell-container" @input="calculate">
    <div class="inputs-row">
      <div class="balance">
        <span> موجودی : </span>
        <span>
          {{ $toLocal(balance, $decimal[$route.params.tradeFrom]) }}
          {{ $coinLabel[$route.params.tradeFrom] }}
        </span>
      </div>
      <!--unit price-->
      <div class="label-container">
        <div :class="fUnitPrice ? 'div-active' : ''" class="input-p2p">
          <label>
            <span
              :class="fUnitPrice || unitPrice ? 'span-active-p2p' : ''"
              class="secondary"
            >
              قیمت
            </span>
            <input
              @input="
                unitPrice = $toLocal(unitPrice, $decimal[$route.params.tradeTo])
              "
              @focus="fUnitPrice = true"
              v-model="unitPrice"
              type="text"
              :disabled="limit === 'market'"
            />
            <img :src="'/coins/' + $route.params.tradeTo + '.png'" alt="" />
          </label>
        </div>
      </div>
      <!--          stop limit-->
      <div class="label-container" v-if="limit === 'stop-limit'">
        <div :class="fstopPrice ? 'div-active' : ''" class="input-p2p">
          <label>
            <span :class="fstopPrice || stopPrice ? 'span-active-p2p' : ''">
              حد توقف
            </span>
            <input
              @input="
                stopPrice = $toLocal(stopPrice, $decimal[$route.params.tradeTo])
              "
              @focus="fstopPrice = true"
              v-model="stopPrice"
              type="text"
            />
          </label>
        </div>
      </div>
      <!--amount-->
      <div class="label-container">
        <div :class="fAmount ? 'div-active' : ''" class="input-p2p">
          <label>
            <span :class="fAmount || amount ? 'span-active-p2p' : ''">
              مقدار
            </span>
            <input
              @input="
                amount = $toLocal(amount, $decimal[$route.params.tradeFrom])
              "
              @focus="fAmount = true"
              v-model="amount"
              type="text"
            />
            <img
              :src="'/coins/' + $coinUnit[$route.params.tradeFrom] + '.png'"
              alt=""
            />
          </label>
        </div>
      </div>
      <!--date picker-->
      <date-picker
        v-if="limit !== 'market'"
        placeholder="تاریخ انقضا"
        v-model="eDate"
        :format="$dateFormat['en']"
        :display-format="$dateFormat['faDateTime']"
        :clearable="true"
        type="datetime"
        :min="now"
        :class="'date-fa'"
        class="date input-container"
      />
    </div>
    <!--input range-->
    <div class="range-fee">
      <div class="input-range">
        <input
          v-model="value"
          @change="setAmount"
          class="rang-inpt"
          min="0"
          max="1"
          step="0.01"
          type="range"
          name=""
          id=""
        />

        <div class="dots-container">
          <span
            v-for="n in [0, 0.25, 0.5, 0.75, 1]"
            :key="n"
            class="dots"
            :style="{
              left: n * 100 + '%',
              transform: 'translate(' + -1 * n * 100 + '%, -50%)',
            }"
          ></span>
        </div>
      </div>
      <div class="fee d-flex justify-space-between w100">
        <span> کارمزد: {{ fee }}% </span>
        <span> مقدار:{{ value }}%</span>
      </div>
      <div class="small d-flex gray">{{ range }}</div>
    </div>
    <!--total price-->
    <div class="label-container income">
      <div class="input-p2p">
        <label class="label">
          <span> دریافتی </span>
          <span class="total">
            {{ $toLocal(totalPrice, $decimal[$route.params.tradeTo]) }}
          </span>
          <img
            :src="'/coins/' + $coinUnit[$route.params.tradeFrom] + '.png'"
            alt=""
          />
        </label>
      </div>
    </div>
    <button
      class="btn-red w100"
      :disabled="!!readonly"
      @click.prevent="trans = !trans"
    >
      {{ !!readonly ? readonly : "فروش" }}
    </button>

        <transaction-modal
      v-if="trans"
      :date="eDate"
      :order-type="limit"
      :stop-price="stopPrice"
      :unit-price="unitPrice"
      type="فروش"
      :fee="fee"
      :amount="amount"
      :total-price="$toLocal(totalPrice)"
      @close="trans = false"
    />
  </form>
</template>

<script>
import {
  Loop,
  tradeFrom,
  tradeTo,
  precision,
} from "../../Lib/reuseableFunction";
import TransactionModal from "@/components/P2P/TransactionModal";
export default {
  name: "Buy",
  props: ["limit", "balance", "role"],
    components: { TransactionModal },
  data() {
    return {
      eDate: null,
      fStopPrice: false,
      stopPrice: null,
      totalPrice: 0,
      percent: 10,
      fee: this.state.userInfo.fee,
      // balance:1,
      fUnitPrice: false,
      unitPrice: null,
      fAmount: false,
      amount: null,
      calculating: false,
      minAmountOrder: null,
      maxAmountOrder: null,
      minAmount: null,
      maxAmount: null,
      getAmount: false,
      loop: undefined,
      trans: false,
    };
  },
  computed: {
    tradeTo,
    tradeFrom,
    precision,

    now() {
      return this.$jmoment().add(5, "minute").format(this.$dateFormat["en"]);
    },
    readonly() {
      console.log("amount:", this.amount, "unitPrice:", this.unitPrice);
      let priceError = "";
      if (this.limit && !this.$S2N(this.unitPrice)) {
        priceError = "قیمت واحد اجباریست";
      } else if (!this.$S2N(this.amount)) {
        priceError = "مقدار ارز اجباریست";
      } else if (this.$S2N(this.amount) < this.min) {
        priceError = "مقدار ارز کمتر از حد مجاز است";
      } else if (this.$S2N(this.amount) > this.max) {
        priceError = "مقدار ارز بیشتر از حد مجاز است";
      } else if (this.$S2N(this.amount) > this.$S2N(this.cash)) {
        priceError = "موجودی کافی نیست";
      }
      // else if (!this.state.userInfo.supervisor) {
      //     priceError = 'موجودی کافی نیست'
      // }
      //  else if (this.load) {
      //     priceError = 'لطفا صبر کنید...'
      // }
      else if (!this.limit && this.state.userInfo.supervisor) {
        priceError = "بازارگردان قادر به انجام سفارش بازار نیست";
      }
      return priceError;
    },

    load() {
      return this.calculating || this.getAmount;
    },
    min() {
      return this.role.minAmount;
    },
    max() {
      return this.role.maxAmount;
    },
    range() {
      return (
        "حداقل " +
        this.$toLocal(this.min) +
        " " +
        this.$coins[this.tradeFrom].persianName +
        "\n" +
        "حداکثر " +
        this.$toLocal(this.max) +
        " " +
        this.$coinLabel[this.tradeFrom]
      );
    },
  },
  watch: {
    "state.buyAmount"() {
      this.amount = this.$toLocal(this.state.buyAmount, this.decimal);
      this.unitPrice = this.$toLocal(
        this.state.buyPrice,
        this.tradeTo === "TOMAN" ? 0 : this.$prcision[this.tradeFrom]
      );
      this.calculate();
    },
    "state.buyPrice"() {
      this.amount = this.$toLocal(this.state.buyAmount, this.decimal);
      this.unitPrice = this.$toLocal(
        this.state.buyPrice,
        this.tradeTo === "TOMAN" ? 0 : this.$prcision[this.tradeFrom]
      );
      this.calculate();
    },
  },
  methods: {


    
  calculate() {
      //  calculations
      if (this.limit !== "market" && this.unitPrice && this.amount) {
        this.localCalculate();
      } else if (this.limit == "market" && this.$S2N(this.amount)) {
        this.serverCalculate();
      }
    },
    localCalculate() {
      const up = this.$S2N(this.unitPrice);
      const a = this.$S2N(this.amount);
      this.totalPrice = this.$toLocal(up * a, this.$decimal[this.tradeTo]);
      if (this.balance == 0) {
        this.percent = 0;
      } else
        this.percent = this.$S2N(this.totalPrice) / this.$S2N(this.balance);
    },
    async serverCalculate() {
      this.loop ? this.loop.stop() : ''
      this.calculating = true
      const a = this.amount
      await this.$sleep(500)
      if (a === this.amount) {
        this.loop = new Loop(this.getPriceFromServer, this.state.time, 'getPriceSell')
        this.loop.start()
      }
    },

        async getPriceFromServer() {

      let amount = this.$S2N(this.amount);
      console.log(amount)
      const [res] = await this.$http.get("/orders/market-buy-sell-whole", {
        params: {
          amount: amount,
          marketType: this.tradeFrom + "_" + this.tradeTo,
          orderType: "MARKET_SELL",
        },
      });
      if (res) {
        // console.log('response server',res)
        this.totalPrice = this.$toLocal(
            res.baseDTO.wholePrice,
            this.precision
        );
        let totalPrice = this.$S2N(this.totalPrice)
        console.log('totalprice', totalPrice / amount)
        this.unitPrice = this.$toLocal(
            this.$S2N(this.totalPrice) / amount,
            this.precision
            // this.tradeTo === "TOMAN" ? 0 : this.$prcision[this.tradeFrom]
        );
        this.percent = this.$S2N(this.totalPrice) / this.cash;
      }
      this.calculating = false;

      console.log('amount', amount)
    },

    setAmount(e) {
      const percent = e.target.value;
      if (this.balance >= 0) {
        this.amount = this.$toLocal(
          this.$S2N(this.balance) * percent,
          this.$decimal[this.tradeFrom]
        );
      } else this.amount = 0;
      this.calculate();
      // this.disableBtn()
    },
    async getAmountFromServer(p) {
      this.percent = p || this.percent;
      let e = this.percent * this.balance;
      if (e) {
        this.getAmount = true;
        const res = await this.$http("/orders/amount-via-price", {
          params: {
            marketType: this.tradeFrom + "_" + this.tradeTo,
            orderType: "MARKET_BUY",
            amount: e,
          },
        });
        this.getAmount = false;
        if (res) {
          this.amount = this.$toLocal(res.baseDTO.wholePrice, this.decimal);
          this.calculate();
        }
      }
    },
    setMin() {
      if (this.limit) {
        this.unitPrice = this.$toLocal(
          this.state.shopInfo.minSell,
          this.tradeTo === "TOMAN" ? 0 : this.$decimal[this.tradeFrom]
        );
        this.danger = false;
        this.calculate();
      }
    },

    async getCoins() {
      this.state.loading = true;
      const res = await this.$axios.get("/coins/details");
      this.coins = res.content;
      this.minAmountOrder = this.coins.find(
        (a) => a.coin == this.tradeFrom
      ).minAmount;
      this.maxAmountOrder = this.coins.find(
        (a) => a.coin == this.tradeFrom
      ).maxAmount;
    },
  },
  created: function () {
    window.addEventListener("click", (event) => {
      if (!event.target.closest(".label-container")) {
        this.fUnitPrice = false;
        this.fAmount = false;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.buy {
  width: 100%;
  background: var(--red);
  color: white;
}

.range-fee {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  .fee {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: var(--a-hover);
  }
}

.marketWarn {
  opacity: 0;
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  .text {
    color: var(--darker-primary);
    font-weight: 500;
    font-size: clamp(10px, 1vw, 12px);
  }
}

.inputs-row {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  width: 100%;
  .balance {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: var(--black);
    font-weight: 400;
    font-size: clamp(10px, 1vw, 12px);
  }
}
.buysell-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  row-gap: 32px;
}

button {
  height: 40px;
}
.input-container {
  background: #f0f0f0;
  border-radius: 8px 8px 0px 0px;
  height: 40px;
  display: flex;
  align-items: center;
}

.label-container {
  // input{
  //     max-width: 50%;
  // }
}

.secondary {
  color: var(--secondary) !important;
}

@media (max-width: 500px) {
  .label {
    img {
      display: none;
    }
  }
}
</style>