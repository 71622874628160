<template>
<div class="container" :class="{'tables':$route.path.includes('trade')}">
  <DeleteOrder @close="isOpen=!isOpen" v-if="isOpen" :id="id"/>
        <table>
            <tr class="head gray">
                <td style="text-align:start"> بازار </td>
                <td class="remove"> زمان و تاریخ </td>
                <td class="remove"> سمت </td>
              <td class="remove">نوع</td>
                <td> قیمت واحد </td>
                <td> مقدار </td>
                <td class="remove"> کارمزد </td>
                <td class="remove2"> مبلغ کل </td>
                <td class="remove"> پر شده </td>
                <td style="text-align:end"> 
                    <button class="fail-btn"> لغو همه </button>     
                </td>
            </tr>
            <tr v-for="(item,index) in listTables" :key="index" class="body hover pointer">
              <td class="rtl"> {{
                  item.orderType.includes('BUY') ? ($coins[item.destinationCoin].persianName + '-' + $coins[item.sourceCoin].persianName) : ($coins[item.sourceCoin].persianName + '-' + $coins[item.destinationCoin].persianName)
                }}
              </td>
                <td> {{$G2J(item.createdAtDateTime)}} </td>
                <td class="remove" :class="item.orderType.includes('BUY') ? 'green':'red'" > {{item.orderType.includes('BUY') ? ' خرید ':' فروش '}} </td>
              <td class="remove" :class="item.orderType.includes('BUY') ? 'green':'red'">
                {{ item.orderType.includes('MARKET') ? 'بازار' : 'حد'}}
              </td>
                <td class="remove2"> {{$toLocal(item.unitPrice)}} </td>
                <td> {{$toLocal(item.amount,$decimal[item.sourceCoin])}} </td>
                <td class="remove"> {{item.wagePercent}}% </td>
                <td class="remove2"> {{$toLocal(item.wholePrice)}} </td>
                <td class="remove"> {{item.executedPercent}}% </td>
                <td class="red" style="text-align:end;padding-left:14px" @click.prevent="cancelOrder(item.id)"> لغو سفارش </td>
            </tr>
    </table>
</div>
</template>

<script>
    import DeleteOrder from "@/components/Panel/Dashboard/DeleteOrder";
    export default {
        name:'ActiveOrders',
      components: {DeleteOrder},
      data() {
            return {
              isOpen:false,
                listTables:[

                ],
              id:null,
            }
        },
      methods:{
        async getOpenOrders(){
          const res = await this.$axios('/orders',{
            params:{
              orderStatus:'IS_OPEN'
            }
          })
          // console.log(res.content)
          this.listTables =res.content;
        },
        async cancelOrder(id){
          this.id = id;
          this.isOpen = true;
        }
      },
      mounted() {
this.getOpenOrders()
        }

    }
</script>

<style lang="scss" scoped>
.container{
  display: flex;
  width: 100%;
  max-width: none !important;
  background: #FAF9F7;
  border-radius: 8px;
  max-height: 200px;
  overflow-y: scroll;
  padding:0 10px 10px ;
}
.fail-btn{

    color: var(--red);
    font-weight: 500;
        font-size: clamp(8px,1vw,12px);
}
table{
  td:first-child{
    text-align: right !important;
  }
  td:last-child{
    text-align: left !important;
  }
  td{

    text-align: center !important;
    height: 56px;
  }
  td{
    padding:0 10px !important;
  }

    width: 100%;
    .head{
      position: sticky;
      top: 0;
      background: var(--darker-white);
td{

  height: 56px;
}
    }
    .body{
  td{
    height: 56px;
    border-bottom: 1px solid var(--gray-lighter);
  }
      td:last-child{
        text-decoration: underline;
      }
}
    td{
        width: 10%;
        text-align: center;
        // height: 35px;
        font-weight: 500;
        font-size: clamp(8px,1vw,12px);
    }
}
@media only screen and(max-width:900px){
    .remove{
        display: none;
    }
}
@media only screen and(max-width:600px){
    .remove2{
        display: none;
    }
}


.tables{
  max-height: 300px;
  min-height: 300px;
  overflow: auto;
}

</style>