<template>
  <div class="register-container" @submit.prevent="submition">
    <form class="form">
<div class="title d-flex flex-col">
          <h3 class="secondary">درخواست رمز عبور جدید</h3>

</div>
<div class="email-container d-flex flex-col w100 ">
          <p>لطفا ایمیل خود را وارد کنید.</p>

      <div class="input-container d-flex gap-xss align-items-center w100">
        <img src="../../assets/Icons/Email.svg" alt="" width="16px" height="16px">
        <input type="text" placeholder="ایمیل" v-model="email">
      </div>
</div>

      <button class="btn-primary" id="btn" :disabled="!email">تایید</button>

    </form>
  </div>
</template>

<script>

const goto = {
  EMAIL: '/auth/newpass',
  // SMS: '/',
  // GOOGLE_AUTHENTICATOR: '/',
}

export default {
  name: "Forget",
  data() {
    return {
      email: ''
    }
  },
  methods: {
    async checkMail() {
      if (!this.$validEmail(this.email) || !this.email) {
        this.emailError = 'ایمیل صحیح وارد نمایید'
        this.$error('مقادیر اشتباه', 'ایمیل وارد شده صحیح نیست', '', '', '', '2500')
        return Promise.reject('repeat failed : ' + this.email)
      } else return this.emailError = ''

    },


    async submition() {
      this.checkMail()
      if (!this.emailError) {
        const res = await this.$axios.post('/users/forget-password?email=' + this.email)
        if (res.message === 'code sent successfully.') {
          this.state.userInfo.email = this.email;
          this.state.userInfo.mobilenumber = res.baseDTO.starTwoStepAuthentication || '';
          this.state.userInfo.towStepAuthenticationType = res.baseDTO.towStepAuthenticationType;
        } else {
          this.state.userInfo.towStepAuthenticationType = 'EMAIL'
        }
        await this.$router.push(goto[res.baseDTO.towStepAuthenticationType])


      }


    }
  }
}
</script>

<style lang="scss" scoped>
.forget {
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: clamp(20px, 2vw, 24px);
    font-weight: 500;
  }

  p {
    align-self: flex-start;
  }
}

.input-container {
//   color: white;
  background: #F0F0F0;
  height: 48px;
  padding: .7rem;
  border-radius: 8px 8px 0px 0px;
}

.title{
    width: 100%;
    gap:10px;

h3{
    align-self: center;
}

p{
    align-self: flex-start;
}
}


.email-container{
    gap: 1rem;
}





.register-container {
  width: 100%;
  max-width: 460px;
  background: var(white);
  box-shadow: 0px 0px 8px -1px rgba(65, 70, 192, 0.2);
  border-radius: 12px;
  padding: 24px ;
  display: flex;
  flex-direction: column;

  row-gap: 50px;
  align-items: center;
  h2 {
    align-self: center;
  }
  .form{
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
      gap: 50px;
      button{
          width: 100%;
          height:44px;
      }
  }
}
</style>